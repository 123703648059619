import { Injectable } from '@angular/core';
import { Observable, throwError  } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { apiEndpoints } from '../models/HttpRequestModel';
import { FilterModel } from '../models/FilterModel';

export interface ApiResponse{
  transactions: {
    current_page: number;
    data: any[]; // This is the array of transactions
    first_page_url: string;
    last_page: number;
    last_page_url: string;
    next_page_url: string | null;
    prev_page_url: string | null;
    total: number;
  };
  web_user: any;
  success: boolean;
  data: object[] | object;
}


const defaultPostHttpOptions = {
  headers: new HttpHeaders({
    // 'Content-Type':  'text/html'
    'Content-Type':  'application/json'
  })
};


@Injectable({
  providedIn: 'root'
})
export class ApiRequestService {
  
  constructor(private httpClient: HttpClient) {}
  

  submitPostRequest(url: string, payload: object): Observable<ApiResponse>{
    return this.httpClient.post<any>(url, payload); 
  }

  submitGetRequest(url: string): Observable<ApiResponse>{
    return this.httpClient.get<ApiResponse>(url); 
  }


  extractFilterParams(filter: FilterModel): object{
    let apiFilterParams = new Object();

    if(filter.name !== undefined && filter.name.length){
      apiFilterParams["name"] = filter.name;
    }
    
    if(filter.cities !== undefined && filter.cities.length){
      apiFilterParams["city"] = filter.cities[0].name;
    }
    
    if(filter.serviceTypes !== undefined && filter.serviceTypes.length){
      apiFilterParams["service_type_id"] = filter.serviceTypes[0].id;
    }   

    if(filter.specialisations !== undefined && filter.specialisations.length){
      apiFilterParams["specialisation_id"] = filter.specialisations[0].id;
    }
    if(filter.qualifications !== undefined && filter.qualifications.length){
      apiFilterParams["qualification_id"] = filter.qualifications[0].id;
    }

    return apiFilterParams;
  }


  urlGetRequest(apiEndpoint: string, filter: FilterModel): string{
    let finalUrl = apiEndpoint;
    
    let urlParams = [];
    let apiFilterParams = this.extractFilterParams(filter);
    for(let filterParam in apiFilterParams){
      urlParams.push(encodeURIComponent(filterParam) + '=' + encodeURIComponent(apiFilterParams[filterParam]))
    }

    if(urlParams.length){
      finalUrl += '?' + urlParams.join('&');
    }

    return finalUrl;
  }


  getClinicsList(filter: FilterModel): Observable<ApiResponse>{
    let apiUrl = this.urlGetRequest(apiEndpoints.clinicsList, filter);
    return this.submitGetRequest(apiUrl);
  }


  getPromotedClinicsList(filter: FilterModel): Observable<ApiResponse>{
    let apiUrl = this.urlGetRequest(apiEndpoints.promotedClinicsList, filter);
    return this.submitGetRequest(apiUrl);
  }


  getMedicsList(filter: FilterModel): Observable<ApiResponse>{
    let apiUrl = this.urlGetRequest(apiEndpoints.medicsList, filter);
    return this.submitGetRequest(apiUrl);
  }


  getOffersList(filter: FilterModel): Observable<ApiResponse>{
    let apiUrl = this.urlGetRequest(apiEndpoints.offersList, filter);
    return this.submitGetRequest(apiUrl);
  }


  getServiceTypesList(filter: FilterModel): Observable<ApiResponse>{
    let apiUrl = this.urlGetRequest(apiEndpoints.serviceTypesList, filter);
    return this.submitGetRequest(apiUrl);
  }


  getCitiesList(): Observable<ApiResponse>{
    return this.submitGetRequest(apiEndpoints.citiesList);
  }

  getNewsList(): Observable<ApiResponse>{
    return this.submitGetRequest(apiEndpoints.newsList);
  }


  getSpecialisationsList(): Observable<ApiResponse>{
    return this.submitGetRequest(apiEndpoints.specialisationsList);
  }


  getQualificationsList(): Observable<ApiResponse>{
    return this.submitGetRequest(apiEndpoints.qualificationsList);
  }


  getServicesList(): Observable<ApiResponse>{
    return this.submitGetRequest(apiEndpoints.servicesList);
  }


  getClinicInfo(clinicId: number): Observable<ApiResponse>{
    return this.submitGetRequest(apiEndpoints.clinicsList + '/' + clinicId);
  }

  getNewsInfo(newsId: number): Observable<ApiResponse>{
    return this.submitGetRequest(apiEndpoints.newsList + '/' + newsId);
  }


  login(email: String, password: String): Observable<ApiResponse>{
    return this.submitPostRequest(apiEndpoints.login, {email, password});
  }

  logout(): Observable<ApiResponse>{
    return this.submitPostRequest(apiEndpoints.logout, null);
  }

  register(email: string, password:string, passwordCheck: string): Observable<ApiResponse>{
    return this.submitPostRequest(apiEndpoints.register, {email, password, password_confirmation: passwordCheck});
  }

  resetPasswordEmail(email: string): Observable<ApiResponse>{
    return this.submitPostRequest(apiEndpoints.resetPasswordEmail, {email});
  }
  
  resetPassword(token: string, email: string, password: string, password_confirmation: string): Observable<ApiResponse>{
    return this.submitPostRequest(apiEndpoints.resetPassword, {token, email, password, password_confirmation});
  }
  getProfile(token: String): Observable<ApiResponse>{
    return this.submitPostRequest(apiEndpoints.profile, null)
  }

  addFavouriteOffer(offerId: number, webUserId: number, token: string): Observable<ApiResponse>{
    return this.submitPostRequest(`${apiEndpoints.favouritesToggle}/${webUserId}/add-offer/${offerId}`,{token});
  }

  deleteFavouriteOffer(offerId: number, webUserId: number, token: string): Observable<ApiResponse>{
    return this.submitPostRequest(`${apiEndpoints.favouritesToggle}/${webUserId}/delete-offer/${offerId}`,{token});
  }

  addFavouriteClinic(clinicId: number, webUserId: number, token: string): Observable<ApiResponse>{
    return this.submitPostRequest(`${apiEndpoints.favouritesToggle}/${webUserId}/add-clinic/${clinicId}`,{token});
  }
  
  deleteFavouriteClinic(clinicId: number, webUserId: number, token: string): Observable<ApiResponse>{
    return this.submitPostRequest(`${apiEndpoints.favouritesToggle}/${webUserId}/delete-clinic/${clinicId}`,{token});
  }

  addFavouriteMedic(medicId: number, webUserId: number, token: string): Observable<ApiResponse>{
    return this.submitPostRequest(`${apiEndpoints.favouritesToggle}/${webUserId}/add-medic/${medicId}`,{token});
  }
  
  deleteFavouriteMedic(medicId: number, webUserId: number, token: string): Observable<ApiResponse>{
    return this.submitPostRequest(`${apiEndpoints.favouritesToggle}/${webUserId}/delete-medic/${medicId}`,{token});
  }

  getTosGeneral(): Observable<ApiResponse>{
    return this.submitGetRequest(`${apiEndpoints.tosGeneral}`);
  }
  getTosWeb(): Observable<ApiResponse>{
    return this.submitGetRequest(`${apiEndpoints.tosWeb}`);
  }
  getTosPrivacy(): Observable<ApiResponse>{
    return this.submitGetRequest(`${apiEndpoints.tosPrivacy}`);
  }
  getTosCustomersData(): Observable<ApiResponse>{
    return this.submitGetRequest(`${apiEndpoints.tosCustomersData}`);
  }
  getTosCookiesWeb(): Observable<ApiResponse>{
    return this.submitGetRequest(`${apiEndpoints.tosCookiesWeb}`);
  }

  getMedcoinTransactions(userId: string, token: string, params: HttpParams): Observable<ApiResponse> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const url = apiEndpoints.medcoinTransactions(userId);
    return this.httpClient.get<ApiResponse>(url, { headers, params });
  }

  searchUsers(query: string, type: string, currentUserId: number): Observable<ApiResponse> {
    const url = apiEndpoints.searchUsers;
    const payload = { query, type, currentUserId };

    return this.submitPostRequest(url, payload);
  }

  transferMedcoins(transferData: any): Observable<ApiResponse> {
    const url = apiEndpoints.transferMedcoins;
    return this.submitPostRequest(url, transferData);
  }


}
