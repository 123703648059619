import { MedicModel } from "./MedicModel";
import { OfferModel } from "./OfferModel";
import { ServiceModel } from "./ServiceModel";

export class ClinicModel{
    id: number;
    name: string;
    city_id: string;
    city_name: string;
    description: string;
    phone: string;
    address: string;
    web_site: string;
    logo_link: string;
    cover_link: string;
    latitude: number;
    longitude: number;
    week_opening: string;
    week_closing: string;
    saturday_opening: string;
    saturday_closing: string;
    sunday_opening: string;
    sunday_closing: string;
    medics: MedicModel[];
    active_offers: OfferModel[];
    active_services: ServiceModel[];
    in_logged_user_favorites: boolean;
}