import { Injectable } from '@angular/core';
import { ApiRequestService } from './api-request.service';

@Injectable({
  providedIn: 'root'
})
export class TermsOfServiceService {
  termsOfServiceInfo: object = {
    name: '',
    short_description: '',
    description: ''
  };

  constructor(public apiRequestService: ApiRequestService) { }

  getTermsOfService(route: string) {
    switch (route) {
      // case 'general':
      //   this.getTosGeneral();
      //   break;

      case 'web':
        this.getTosWeb();
        break;
        
      case 'confidentialitate':
        this.getTosPrivacy();
        break;

      case 'date-clienti':
        this.getTosCustomersData();
        break;
    
      default:
        break;
    }
  }

  getTosGeneral(){
    this.apiRequestService.getTosGeneral().subscribe (
      success => Object.assign(this.termsOfServiceInfo, success),
      err => console.error(err)
    );
  }

  getTosWeb(){
    this.apiRequestService.getTosWeb().subscribe (
      success => Object.assign(this.termsOfServiceInfo, success),
      err => console.error(err)
    );
  }
  
  getTosPrivacy(){
    this.apiRequestService.getTosPrivacy().subscribe (
      success => Object.assign(this.termsOfServiceInfo, success),
      err => console.error(err)
    );
  }
  
  getTosCustomersData(){
    this.apiRequestService.getTosCustomersData().subscribe (
      success => Object.assign(this.termsOfServiceInfo, success),
      err => console.error(err)
    );
  }
}
