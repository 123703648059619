import { Component,  OnInit} from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Location } from '@angular/common';

// Models
import { QuickSearchModel } from 'app/models/QuickSearchModel';
import { FilterModel } from 'app/models/FilterModel';

// Services
import { MedicsListService } from 'app/services/medics-list.service';
import { ClinicsListService } from 'app/services/clinics-list.service';
import { OffersListService } from 'app/services/offers-list.service';
import { ServiceTypesListService } from 'app/services/service-types-list.service';

@Component({
  selector: 'app-quick-search',
  templateUrl: './quick-search.component.html',
  styleUrls: ['./quick-search.component.css'],
})
export class QuickSearchComponent implements OnInit {

  quickSearchResults: QuickSearchModel;
  defaultSearchTerm: string = '';
  servicesLength: number;

  constructor(
    private medicsListService: MedicsListService,
    private clinicsListService: ClinicsListService,
    private offersListService: OffersListService,
    private serviceTypesListService: ServiceTypesListService,
    private route: ActivatedRoute,
    private location: Location
   ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.defaultSearchTerm = params.get("searchName");

      this.quickSearchResults = new QuickSearchModel();
      this.quickSearchResults.medics = this.medicsListService.itemsList;
      this.quickSearchResults.clinics = this.clinicsListService.itemsList;
      this.quickSearchResults.offers = this.offersListService.itemsList;
      this.quickSearchResults.serviceTypes = this.serviceTypesListService.itemsList;

      let filter = new FilterModel();
      filter.name = this.defaultSearchTerm;
      this.getSearchLists(filter);     
    })
  }

  getSearchLists(filter: FilterModel) {
    if(!filter.name) {
      filter.name = "";
    }

    this.location.go(`/cautare-rapida/${filter.name}`);
    this.medicsListService.loadItemsList(filter);
    this.clinicsListService.loadItemsList(filter);
    this.offersListService.loadItemsList(filter);
    this.serviceTypesListService.loadItemsList(filter);
  }

  getServicesLength(length: number) {
    this.servicesLength += length;
  }

}
