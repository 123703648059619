import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, observable, Observable} from 'rxjs';

import { UiNotificationsService } from '../shared/ui-notifications/ui-notifications.service';

import { appConfig } from '../models/AppConfigModel';
import { ApiRequestService, ApiResponse } from './api-request.service';
import { WebUserModel } from 'app/models/WebUserModel';
import { FavouritesModel } from 'app/models/FavouritesModel';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  appConfig = appConfig;

  private loggedUser: WebUserModel;
  private localStorageIndex = 'authenticated-user';
  private userSubject: BehaviorSubject<WebUserModel>;
  public userObservable: Observable<WebUserModel>;
  public favourites: FavouritesModel;

  constructor(
    private apiRequestService: ApiRequestService,
    private router: Router, 
    private uiNotificationService: UiNotificationsService
  ) {

    this.loggedUser = JSON.parse(localStorage.getItem(this.localStorageIndex));
    this.favourites = {offers: [], clinics:[], medics: []};
    
    this.userSubject = new BehaviorSubject<WebUserModel>(this.loggedUser);
    this.userObservable = this.userSubject.asObservable();
  }  


  public get userValue(): WebUserModel {
    return this.userSubject.value;
  }


  handleApiError(response: any){
    switch(response.status){
      case 401:
        this.resetAuthenticatedUser();
        break;
      
      default:
        console.log('Auth Error');
        console.log(response);
    }
  }


  handleUnauthorizedUser(response: any){
    console.log('user unauthorised!');
    console.log(response);
  }


  login(user: String, password: String): Observable<ApiResponse>{
    let source = this.apiRequestService.login(user, password);
    this.apiRequestService.login(user, password).subscribe(
      apiResponse =>  this.loginSuccessful(apiResponse),
      apiError => this.loginError(apiError)
    )
    return source;
  }


  loginSuccessful(apiResponse: any){
    let user = {
      id: apiResponse.web_user.id,
      verified: apiResponse.user.verified,
      accessToken: apiResponse.user.access_token,
      name: apiResponse.user.name,
      email: apiResponse.web_user.email,
      authTs: new Date(),
      favourites: apiResponse.wishlist
    }
    this.registerAuthenticatedUser(user);
  }


  loginError(apiError: HttpErrorResponse ){
    switch(apiError.status){
      case 401:
        this.uiNotificationService.pushNotification({
          strong: 'Autentificare esuata!',
          message: 'Verificati datele de conectare.',
          type: 'danger',
          icon: 'business_bulb-63'
        })
        break;

      default:
        console.log('Auth Error!');
        console.log(apiError);
    }
  }


  registerAuthenticatedUser(user: WebUserModel){
    localStorage.setItem(this.localStorageIndex, JSON.stringify(user));
    this.userSubject.next(user);
    this.router.navigate([appConfig.appModules.account.routes.profile]);
  }


  resetAuthenticatedUser(){
    localStorage.removeItem(this.localStorageIndex);
    this.userSubject.next(null);
  }


  logout(){
    this.apiRequestService.logout().subscribe(
      () => {
        this.resetAuthenticatedUser();
        this.router.navigate([appConfig.appModules.account.routes.login])
      },
      err => this.handleApiError(err)
    );
  }


  getProfile(){
    this.apiRequestService.getProfile(this.userValue.accessToken)
      .subscribe(
        res => this.getUserProfileSuccess(res),
        err => this.handleApiError(err)
      );
  }


  getUserProfileSuccess(apiResponse: any){
    this.loggedUser = {...apiResponse.web_user};

    this.favourites.clinics.length = 0;
    this.favourites.clinics.push(...apiResponse.wishlist.clinics);    

    this.favourites.offers.length = 0;
    this.favourites.offers.push(...apiResponse.wishlist.offers);

    this.favourites.medics.length = 0;
    this.favourites.medics.push(...apiResponse.wishlist.medics);
  }
}
