import { Pipe, PipeTransform } from '@angular/core';
import { ServiceTypeModel } from 'app/models/ServiceTypeModel';

@Pipe({
  name: 'countItems'
})
export class CountItemsPipe implements PipeTransform {

  transform(items: any, ...args: Array<any|string>): number {
    switch(args[1]){
      case 'services':
        return this.countServices(args[0]);

      default:
        return args[0].length;
    }
  }


  countServices(services:ServiceTypeModel[]): number{
    let servicesLength = 0;
    services.forEach(service => servicesLength += service.active_services.length);

    return servicesLength;
  }

}
