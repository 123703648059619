import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NewPasswordService, ResetPasswordRequestErrorMessages } from '../../services/new-password.service';

import { appConfig } from '../../models/AppConfigModel';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.css']
})
export class NewPasswordComponent implements OnInit {

  appConfig = appConfig;
  passwordFocus: boolean = false;
  passwordValidationsFocus: boolean = false;

  token: string = '';
  userEmail: string = '';
  newPassword: string = '';
  passwordValidation: string = '';

  requestStatusSubscription: Subscription;
  requestInProgress: boolean = false;

  errors: ResetPasswordRequestErrorMessages;


  constructor(
    private activatedRoute: ActivatedRoute, 
    private newPasswordService: NewPasswordService
  ) {
    this.errors = this.newPasswordService.resetPasswordRequestResult.errorMessages;
  }


  ngOnInit() {
    var body = document.getElementsByTagName('body')[0];
    body.classList.add('login-page');
    var navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.add('navbar-transparent');

    this.activatedRoute.queryParams.subscribe(params => {
      this.token = params['token'];
      this.userEmail = params['email'];
    });

    this.requestStatusSubscription = this.newPasswordService.newPasswordSubjectBehaviour.subscribe(
      requestInProgress => {
        this.requestInProgress = requestInProgress;
      }
    );
  }


  ngOnDestroy(){
      var body = document.getElementsByTagName('body')[0];
      body.classList.remove('login-page');
      var navbar = document.getElementsByTagName('nav')[0];
      navbar.classList.remove('navbar-transparent');

      this.requestStatusSubscription.unsubscribe();
  }


  resetPassword(){
    this.requestInProgress = true;
    this.newPasswordService.newPasswordRequest(this.token, this.userEmail, this.newPassword, this.passwordValidation);
  }

}
