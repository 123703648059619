import { Component, OnInit, EventEmitter, Output } from '@angular/core';

// models
import { appConfig } from '../../models/AppConfigModel';
import { DropdownItemModel } from '../../models/DropdownItemModel';

// services
import { SpecialisationDropdownItemsService } from '../../services/specialisation-dropdown-items.service';

@Component({
  selector: 'app-specialisation-dropdown',
  templateUrl: './specialisation-dropdown.component.html',
  styleUrls: ['./specialisation-dropdown.component.css']
})
export class SpecialisationDropdownComponent implements OnInit {

  @Output() selectSpecialisationsEvent = new EventEmitter<DropdownItemModel[]>();

  appConfig = appConfig;

  dropdownList: DropdownItemModel[] = [];
  selectedItems: DropdownItemModel[] = [];
  dropdownSettings = {};

  constructor(private specialisationDropdownItemsService: SpecialisationDropdownItemsService) { }

  ngOnInit() {
    this.dropdownList = this.specialisationDropdownItemsService.itemsList;

    this.selectedItems = [];
    
    this.dropdownSettings = {
      singleSelection: true,
      text:"Selecteaza specializare",
      selectAllText:'Selecteaza toate',
      unSelectAllText:'Deselecteaza toate',
      enableSearchFilter: false,
      classes:"",
      lazyLoading: true
    };
  }

  onItemSelect(item:any){
    this.notifySelectionChange();
  }
  
  OnItemDeSelect(item:any){
    this.notifySelectionChange();
  }
  
  onSelectAll(items: any){
    this.notifySelectionChange();
  }
  
  onDeSelectAll(items: any){
    this.notifySelectionChange();
  }
  
  notifySelectionChange(){
    this.selectSpecialisationsEvent.emit(<DropdownItemModel[]>this.selectedItems);
  }
}
