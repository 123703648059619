import { Component, OnInit, Input } from '@angular/core';

import { FavouritesService } from 'app/services/favourites.service';
import { AuthenticationService } from 'app/services/authentication.service';

import { appConfig } from '../../models/AppConfigModel';
import { MedicModel } from '../../models/MedicModel';
import { WebUserModel } from '../../models/WebUserModel';

@Component({
  selector: 'app-medic-widget',
  templateUrl: './medic-widget.component.html',
  styleUrls: ['./medic-widget.component.css'],
})
export class MedicWidgetComponent implements OnInit {
  
  @Input() medic: MedicModel;

  appConfig = appConfig;
  webUser: WebUserModel = null;

  constructor(
    private favouritesService: FavouritesService, 
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.authenticationService.userObservable.subscribe(
      webUser => { this.webUser = webUser }
    )
  }

  toggleFavouriteItem(isFavourite: boolean){
    if(isFavourite){
      this.favouritesService.addFavouriteMedic(this.medic.id);
    }
    else{
      this.favouritesService.deleteFavouriteMedic(this.medic.id);
    }
  }

}
