import { Component, OnInit, Input } from '@angular/core';

import { appConfig } from '../../models/AppConfigModel';
import { OfferModel } from '../../models/OfferModel';

@Component({
  selector: 'app-offer-content',
  templateUrl: './offer-content.component.html',
  styleUrls: ['./offer-content.component.css']
})
export class OfferContentComponent implements OnInit {

  @Input() offer: OfferModel;

  appConfig = appConfig;

  constructor() { }

  ngOnInit(): void {
  }

}
