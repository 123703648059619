import { Component, OnInit, ɵConsole } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { appConfig } from '../../models/AppConfigModel';
import { ClinicInfoService } from '../../services/clinic-info.service';
import { ClinicModel } from '../../models/ClinicModel';

@Component({
  selector: 'app-clinic-info',
  templateUrl: './clinic-info.component.html',
  styleUrls: ['./clinic-info.component.css']
})
export class ClinicInfoComponent implements OnInit {

  appConfig = appConfig;

  clinicId: number;
  clinicInfo: ClinicModel;

  constructor(
    private clinicInfoService: ClinicInfoService,
    private route: ActivatedRoute,
    private location: Location
  ) { }

  ngOnInit(): void {
    let clinicId = parseInt(this.route.snapshot.paramMap.get('clinicId'));
    this.clinicInfo = this.clinicInfoService.clinicInfo;
    this.getClinicInfo(clinicId);
  }

  private getClinicInfo(clinicId: number){
    this.clinicId = clinicId;
    this.clinicInfoService.getClinicInfo(clinicId);
  }
}
