import { Component, OnInit, Input } from '@angular/core';

import { appConfig } from '../../models/AppConfigModel';
import { MedicModel } from '../../models/MedicModel';

@Component({
  selector: 'app-medic-content',
  templateUrl: './medic-content.component.html',
  styleUrls: ['./medic-content.component.css']
})
export class MedicContentComponent implements OnInit {
    
  @Input() medic: MedicModel;

  appConfig = appConfig;

  constructor() { }

  ngOnInit(): void {
  }

}
