import { Component, OnInit, Input } from '@angular/core';

import { FavouritesService } from 'app/services/favourites.service';
import { AuthenticationService } from 'app/services/authentication.service';

import { appConfig } from '../../models/AppConfigModel';
import { OfferModel } from '../../models/OfferModel';
import { WebUserModel } from '../../models/WebUserModel';

@Component({
  selector: 'app-simple-offer-widget',
  templateUrl: './simple-offer-widget.component.html',
  styleUrls: ['./simple-offer-widget.component.css']
})
export class SimpleOfferWidgetComponent implements OnInit {

  @Input() offer: OfferModel;

  appConfig = appConfig;
  webUser: WebUserModel = null;

  constructor(
    private favouritesService: FavouritesService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.authenticationService.userObservable.subscribe(
      webUser => { this.webUser = webUser }
    )
  }

  toggleFavouriteItem(isFavourite: boolean){
    if(isFavourite){
      this.favouritesService.addFavouriteOffer(this.offer.id);
    }
    else{
      this.favouritesService.deleteFavouriteOffer(this.offer.id);
    }
  }
}
