import { Component, OnInit } from '@angular/core';
import { ApiRequestService } from 'app/services/api-request.service';
import { AuthenticationService } from 'app/services/authentication.service';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-medcoins-transactions',
  templateUrl: './medcoins-transactions.component.html',
  styleUrls: ['./medcoins-transactions.component.css']
})
export class MedcoinsTransactionsComponent implements OnInit {

  userId: string;
  profile: any;
  transactions: any[] = [];
  loading = false;
  currentPage = 1;
  totalPages = 1;
  perPage = 10;
  sortField = 'created_at';
  sortOrder = 'desc';

  senderName: string;
  receiverName: string;
  senderEmail: string;
  receiverEmail: string;
  senderType: string;
  receiverType: string;
  startDate: string;
  endDate: string;

  constructor(
    private apiRequestService: ApiRequestService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.getProfile();
  }

  getProfile() {
    const token = this.authenticationService.userValue.accessToken;
    this.apiRequestService.getProfile(token).subscribe(
      (response) => {
        if (response.success) {
          this.profile = response.web_user;
          this.userId = this.profile.id;
          console.log(this.profile);
          this.getTransactions();
        }
      },
      (error) => {
        console.error('Error fetching profile information', error);
      }
    );
  }

  getTransactions() {
    this.loading = true;
    const token = this.authenticationService.userValue.accessToken;

    let params = new HttpParams()
      .set('sort_field', this.sortField)
      .set('sort_order', this.sortOrder)
      .set('per_page', this.perPage.toString())
      .set('page', this.currentPage.toString());

    if (this.senderName) params = params.set('sender_name', this.senderName);
    if (this.receiverName) params = params.set('receiver_name', this.receiverName);
    if (this.senderEmail) params = params.set('sender_email', this.senderEmail);
    if (this.receiverEmail) params = params.set('receiver_email', this.receiverEmail);
    if (this.senderType) params = params.set('sender_type', this.senderType);
    if (this.receiverType) params = params.set('receiver_type', this.receiverType);
    if (this.startDate) params = params.set('start_date', this.startDate);
    if (this.endDate) params = params.set('end_date', this.endDate);

    this.apiRequestService.getMedcoinTransactions(this.userId, token, params).subscribe(
      (response) => {
        if (response.success) {
          this.transactions = response.transactions.data;
          this.totalPages = response.transactions.last_page;
          console.log(this.transactions);
        }
        this.loading = false;
      },
      (error) => {
        console.error('Error fetching transactions', error);
        this.loading = false;
      }
    );
  }

  clearFilters() {
    this.senderName = '';
    this.receiverName = '';
    this.senderEmail = '';
    this.receiverEmail = '';
    this.senderType = '';
    this.receiverType = '';
    this.startDate = '';
    this.endDate = '';
    this.getTransactions();
  }

  sort(field: string) {
    if (this.sortField === field) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      this.sortField = field;
      this.sortOrder = 'asc';
    }
    this.getTransactions();
  }

  changePage(page: number) {
    this.currentPage = page;
    this.getTransactions();
  }
}
