import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { appConfig } from '../../models/AppConfigModel';
import { NewsInfoService } from 'app/services/news-info.service';
import { NewsModel } from 'app/models/NewsModel';

@Component({
  selector: 'app-news-info',
  templateUrl: './news-info.component.html',
  styleUrls: ['./news-info.component.css']
})
export class NewsInfoComponent implements OnInit {

  appConfig = appConfig;

  newsId: number;
  newsInfo: NewsModel;

  constructor(
    private newsInfoService: NewsInfoService,
    private route: ActivatedRoute,
    private location: Location
  ) { }

  ngOnInit(): void {
    let newsId = parseInt(this.route.snapshot.paramMap.get('newsId'));
    this.newsInfo = this.newsInfoService.newsInfo;
    this.getNewsInfo(newsId);
  }

  private getNewsInfo(newsId: number){
    this.newsId = newsId;   
    this.newsInfoService.getNewsInfo(newsId);
  }

}
