import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FeatureWidgetModel } from '../../ui-components/feature-widget/feature-widget.component';

import { appConfig } from '../../models/AppConfigModel';
import { ClinicModel } from '../../models/ClinicModel';

import { ClinicsListService } from '../../services/clinics-list.service';
import { PromotedClinicsService } from '../../services/promoted-clinics.service';
import { OffersListService } from '../../services/offers-list.service';
import { ServicesListService } from '../../services/services-list.service';
import { MedicsListService } from '../../services/medics-list.service';
import { Router } from '@angular/router';
import { NewsModel } from 'app/models/NewsModel';
import { NewsListService } from 'app/services/news-list.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  @ViewChild('searchName', {static: true}) searchName:ElementRef;

  appConfig = appConfig;

  features: FeatureWidgetModel[];
  clinics: ClinicModel[];
  promotedClinics: ClinicModel[];
  newsList: NewsModel[];

  constructor(
    private router: Router,
    private clinicsListService: ClinicsListService,
    private promotedClinicsListService: PromotedClinicsService,
    private offersListService: OffersListService,
    private servicesListService: ServicesListService,
    private medicsListService: MedicsListService,
    private newsListService: NewsListService,
  ) { }

  ngOnInit(): void {
    this.clinics = this.clinicsListService.itemsList;
    this.promotedClinics = this.promotedClinicsListService.itemsList;
    this.newsList = this.newsListService.itemsList;    

    this.features = [
      {
        index: 1,
        route: appConfig.appModules.offers.routes.search,
        icon: appConfig.appModules.offers.icon,
        style: appConfig.appModules.offers.cssColor,
        items: this.offersListService.itemsList,
        title: 'oferte',
        text: 'Vrei sa vezi ofertele curente?',
        description: this.appConfig.appName + ' te tine la curent cu toate ofertele actuale',
        callToAction: 'Cauta oferte'
      },
      {
        index: 2,
        route: appConfig.appModules.serviceTypes.routes.search,
        icon: appConfig.appModules.services.icon,
        style: appConfig.appModules.services.cssColor,
        items: this.servicesListService.itemsList,
        title: 'servicii',
        text: 'Cauti un serviciu?',
        description: this.appConfig.appName + ' te ajuta sa gasesti rapid informatiile de care ai nevoie',
        callToAction: 'Cauta servicii'
      },
      {
        index: 3,
        route: appConfig.appModules.clinics.routes.search,
        icon: appConfig.appModules.clinics.icon,
        style: appConfig.appModules.clinics.cssColor,
        items: this.clinicsListService.itemsList,
        title: 'clinici',
        text: 'Cauti o clinica?',
        description: this.appConfig.appName + ' te ajuta sa localizezi clinicile medicale din orasul tau, si nu numai!',
        callToAction: 'Cauta clinici',
      },
      {
        index: 4,
        route: appConfig.appModules.medics.routes.search,
        icon: appConfig.appModules.medics.icon,
        style: appConfig.appModules.medics.cssColor,
        items: this.medicsListService.itemsList,
        title: 'medici',
        text: 'Cauti un medic?',
        description: this.appConfig.appName + ' te pune in contact cu specialistii din domeniul stomatologic',
        callToAction: 'Cauta medici'
      }
    ];
  }

  goToQuickSearch(page: string) {
    this.router.navigate([`/${page}/${this.searchName.nativeElement.value}`]);
  }
}
