import { Injectable } from '@angular/core';


import { ApiRequestService } from './api-request.service';
import { SpecialisationModel } from '../models/SpecialisationModel';
import { DropdownItemModel } from '../models/DropdownItemModel';

@Injectable({
  providedIn: 'root'
})
export class SpecialisationDropdownItemsService {

  itemsList: DropdownItemModel[] = [];
  
  constructor(private apiReqestService: ApiRequestService) { 
    this.loadItems();
  }

  loadItems(){
    this.apiReqestService.getSpecialisationsList().subscribe(
      apiResponse => this.setItemsList(<SpecialisationModel[]>apiResponse.data),
    err => {
      console.error('Error on fetching cities list');
    });
  }

  setItemsList(newItemsList: SpecialisationModel[]): void{
    this.itemsList.length = 0; // reset the data
    newItemsList.forEach(specialisation => {
      this.itemsList.push({
        id: specialisation.id,
        itemName: specialisation.name
      });
    });
  }
}
