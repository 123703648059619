export const apiUrl: string = 'https://clinica.dentalbooking.ro/api/v1/';
// export const apiUrl: string = 'https://dentbooking.codernapps.ro/api/v1/';
// export const apiUrl: string = 'https://dentalbooking-backend.test/api/v1/';

export const apiEndpoints = {
  citiesList: apiUrl + 'orase',
  newsList: apiUrl + 'noutati',
  specialisationsList: apiUrl + 'specializari',
  qualificationsList: apiUrl + 'calificari',
  clinicsList: apiUrl + 'clinici',
  promotedClinicsList: apiUrl + 'clinici-premium',
  offersList: apiUrl + 'oferte',
  servicesList: apiUrl + 'servicii',
  serviceTypesList: apiUrl + 'tipuri-servicii',
  medicsList: apiUrl + 'medici',
  register: apiUrl + 'auth/register',
  resetPasswordEmail: apiUrl + 'auth/password/email',
  resetPassword: apiUrl + 'auth/password/reset',
  login: apiUrl + 'auth/login',
  logout: apiUrl + 'auth/logout',
  profile: apiUrl + 'auth/me',
  favouritesToggle: apiUrl + 'wishlist',
  tosGeneral: apiUrl + 'tos-data/tos',
  tosWeb: apiUrl + 'tos-data/tos-web',
  tosPrivacy: apiUrl + 'tos-data/confidentialitate',
  tosCustomersData: apiUrl + 'tos-data/date-clienti',
  tosCookiesWeb: apiUrl + 'tos-data/cookies-web',
  medcoinTransactions: (userId: string) => `${apiUrl}medcoins/transactions/${userId}`,
  searchUsers: `${apiUrl}medcoins/search`,
  transferMedcoins: `${apiUrl}medcoins/transfer`
}

export enum HttpRequestStatus {
  none = 'none',
  pending = 'pending',
  error = 'error',
  fail = 'fail',
  success = 'success'
}