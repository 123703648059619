import { Component } from '@angular/core';
import { ApiRequestService } from "app/services/api-request.service";
import { AuthenticationService } from "../../services/authentication.service";

@Component({
  selector: "app-guest-delete-account-form",
  templateUrl: "./guest-delete-account-form.component.html",
  styleUrls: ["./guest-delete-account-form.component.css"],
})
export class GuestDeleteAccountFormComponent {
  data: Date = new Date();
  userEmailFocus: boolean = false;
  userPassFocus: boolean = false;

  loginError: boolean = false;
  authenticationInProgress: boolean = false;
  feedbackMessage: string = "";

  loginEmail: string = '';
  loginPassword: string = '';

  constructor(
    private apiRequestService: ApiRequestService,
    private authenticationService: AuthenticationService
  ) { }

  loginUser() {
    this.initAuthentication();
    this.authenticationService.loginForAccountDeletion(this.loginEmail, this.loginPassword).subscribe(
      () => {
        this.requestAccountDeletion();
      },
      error => {
        if (error.status === 401) {
          this.authenticationFail();
        } else {
          console.error('Authentication error!', error);
        }
      }
    );
  }

  initAuthentication() {
    this.authenticationInProgress = true;
    this.loginError = false;
  }

  authenticationFail() {
    this.authenticationInProgress = false;
    this.loginError = true;
  }

  requestAccountDeletion(): void {
    this.authenticationInProgress = true;
    this.feedbackMessage = "";
    this.loginError = false;

    this.apiRequestService.requestAccountDeletion().subscribe(
      (response) => {
        if (response.success) {
          this.feedbackMessage = "Solicitarea dumneavoastră a fost trimisă cu succes.";
          this.loginError = false;
          setTimeout(() => {
            this.logout();
          }, 3000);
        } else {
          this.feedbackMessage = response.data['message'] || "Eroare: Nu am reușit să procesăm cererea.";
          this.loginError = true;
        }
      },
      () => {
        this.feedbackMessage = "Eroare de server. Încercați din nou mai târziu.";
        this.loginError = true;
      },
      () => {
        this.authenticationInProgress = false;
      }
    );
  }

  logout() {
    this.authenticationService.logout();
  }
}
