import { Component, OnInit } from '@angular/core';

import { ServicesListService } from '../../services/services-list.service';
import { ServiceModel } from '../../models/ServiceModel';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

  servicesList: ServiceModel[];

  constructor(private servicesListService: ServicesListService) { }

  ngOnInit(): void {
    this.servicesList = this.servicesListService.itemsList;
  }
}
