import { Component, OnInit } from '@angular/core';
import { TermsOfServiceService } from 'app/services/terms-of-service.service';
import { ActivatedRoute } from "@angular/router";


@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.css'],
  providers: [TermsOfServiceService]
})
export class TermsAndConditionsComponent implements OnInit {

  termsAndConds: any;
  routeName: string;

  constructor(private termsOfService : TermsOfServiceService, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      let route = params.get("section")
      this.termsAndConds = this.termsOfService.termsOfServiceInfo;
      this.getTosRoute(route);
    })
  }

  private getTosRoute(route: string) {
    this.routeName = route;
    this.termsOfService.getTermsOfService(route);
  }

}
