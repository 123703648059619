import { Component, OnInit } from "@angular/core";
import { appConfig } from "../../models/AppConfigModel";

@Component({
  selector: "app-api-documentation",
  templateUrl: "./api-documentation.component.html",
  styleUrls: ["./api-documentation.component.css"],
})
export class ApiDocumentationComponent implements OnInit {
  appConfig = appConfig;

  copyMessage1: string | null = null;
  copyMessage2: string | null = null;
  copyMessage3: string | null = null;

  ngOnInit(): void {}

  javascriptSnippets = [
    {
      description: "Loghează clientul:",
      code: `

      token = login(email, password)";

      function login(email, password) {

            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                    password,
                })
            };

            fetch('https://clinica.dentalbooking.ro/api/v1/auth/login', options)
                .then(response => response.json())
                .then(data => {
                  if (data.success) {
                      localStorage.setItem('accessToken', data.user.access_token);
                  } else {
                      console.error('Login failed:', data.error);
                  }
                })
                .catch(err => console.error(err));
        }
      `,
    },
    {
      description: "Obține Medcoins disponibili ai unui client:",
      code: `
        const token = localStorage.getItem('accessToken');

        if (!token) {
            console.error('Access token is missing');
            return null;
        }

        var xmlHttp = new XMLHttpRequest();

        xmlHttp.open("GET", "https://clinica.dentalbooking.ro/api/v1/medcoins/available/user_id", false);
        xmlHttp.setRequestHeader('Authorization', 'Bearer token');
        xmlHttp.send(null);

        if (xmlHttp.status === 200) {
            var data = JSON.parse(xmlHttp.responseText);
            return data.medcoins_count;
        } else {
            console.error('Request failed with status:', xmlHttp.status);
            return null;
        }
      `,
    },
    {
      description: "Transferă Medcoins:",
      code: `

      const token = localStorage.getItem('accessToken');

      if (!token) {
          console.error('Access token is missing');
          return null;
      }

      fetch("https://clinica.dentalbooking.ro/api/v1/transfer", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                    Authorization': 'Bearer token',
                },
                body: JSON.stringify({
                    receiver_id: receiver_id,
                    receiver_type: receiver_type,
                    amount: amount
                })
            })
            .then(response => response.json())
            .then(data => console.log('Transfer Response:', data))
            .catch(error => console.error('Error transferring Medcoins:', error));
      `,
    },
  ];

  curlSnippets = [
    {
      description: "Loghează clientul:",
      code: `

        $this->authenticate();

        protected function authenticate()
        {
            $loginUrl = "https://clinica.dentalbooking.ro/api/v1/auth/login";
            $credentials = [
                'email' => 'client_user_email',
                'password' => 'client_user_password',
            ];

            $curl = curl_init();
            curl_setopt($curl, CURLOPT_URL, $loginUrl);
            curl_setopt($curl, CURLOPT_RETURNTRANSFER, true);
            curl_setopt($curl, CURLOPT_POST, true);
            curl_setopt($curl, CURLOPT_POSTFIELDS, json_encode($credentials));
            curl_setopt($curl, CURLOPT_HTTPHEADER, [
                'Accept: application/json',
                'Content-Type: application/json'
            ]);

            $response = curl_exec($curl);
            $statusCode = curl_getinfo($curl, CURLINFO_HTTP_CODE);

            if (curl_errno($curl)) {
                $this->error("Error connecting to $loginUrl");
                $this->error(curl_error($curl));
            } else if ($statusCode === 200) {
                $this->info("Response: " . $response);
                $content = json_decode($response, true);
                return $content['user']['access_token'];
            } else {
                $this->error("Authentication failed. Status code: $statusCode");
                $this->error("Response: " . $response);
            }

            curl_close($curl);
        }
      `,
    },
    {
      description: "Obține Medcoins disponibili ai unui client:",
      code: `

        $token = $this->authenticate();

        $baseUrl = "https://clinica.dentalbooking.ro/api/v1";
        $this->getAvailableMedcoins($token, "{$baseUrl}/available/1", 'GET');

        protected function getAvailableMedcoins($token, $url, $method = 'GET', $data = [])
        {
          $curl = curl_init();

          curl_setopt($curl, CURLOPT_URL, $url);
          curl_setopt($curl, CURLOPT_RETURNTRANSFER, true);
          curl_setopt($curl, CURLOPT_HTTPHEADER, [
              'Accept: application/json',
              'Content-Type: application/json',
              'Authorization: Bearer ' . $token
          ]);

          if ($method === 'POST') {
              curl_setopt($curl, CURLOPT_POST, true);
              curl_setopt($curl, CURLOPT_POSTFIELDS, json_encode($data));
          }

          $response = curl_exec($curl);
          $statusCode = curl_getinfo($curl, CURLINFO_HTTP_CODE);

          if (curl_errno($curl)) {
              $this->error(curl_error($curl));
          } else {
              $this->info("Status code: $statusCode");
              $this->info("Response: " . $response);
          }

          curl_close($curl);
        }
      `,
    },
    {
      description: "Transferă Medcoins:",
      code: `

        $token = $this->authenticate();

        $baseUrl = "https://clinica.dentalbooking.ro/api/v1";

        $this->transferMedcoins("{$baseUrl}/medcoins/transfer", 'POST', [
          'receiver_id' => 2,
          'receiver_type' => 'person',
          'amount' => 10
        ]);

        protected function transferMedcoins($token, $url, $method = 'GET', $data = [])
        {
          $curl = curl_init();

          curl_setopt($curl, CURLOPT_URL, $url);
          curl_setopt($curl, CURLOPT_RETURNTRANSFER, true);
          curl_setopt($curl, CURLOPT_HTTPHEADER, [
              'Accept: application/json',
              'Content-Type: application/json',
              'Authorization: Bearer ' . $token
          ]);

          if ($method === 'POST') {
              curl_setopt($curl, CURLOPT_POST, true);
              curl_setopt($curl, CURLOPT_POSTFIELDS, json_encode($data));
          }

          $response = curl_exec($curl);
          $statusCode = curl_getinfo($curl, CURLINFO_HTTP_CODE);

          if (curl_errno($curl)) {
              $this->error(curl_error($curl));
          } else {
              $this->info("Status code: $statusCode");
              $this->info("Response: " . $response);
          }

          curl_close($curl);
        }
      `,
    },
  ];

  httpSnippets = [
    {
      description: "Loghează clientul:",
      code: `

        use GuzzleHttp\Client;

        $client = new Client();
        $this->authenticate($client);

        protected function authenticate(Client $client)
        {
            $loginUrl = "https://clinica.dentalbooking.ro/api/v1/auth/login";
            $credentials = [
                'email' => $email,
                'password' => $password
            ];

            try {
                $response = $client->post($loginUrl, [
                    'json' => $credentials,
                    'headers' => [
                        'Accept' => 'application/json',
                        'Content-Type' => 'application/json',
                    ],
                ]);

                $statusCode = $response->getStatusCode();
                $content = json_decode($response->getBody(), true);

                $this->info("Status code: $statusCode");
                $this->info("Response: " . json_encode($content));

                return $content['user']['access_token'];

            } catch (\Exception $e) {
                $this->error("Error connecting to $loginUrl");
                $this->error($e->getMessage());
            }
        }
      `,
    },
    {
      description: "Obține Medcoins disponibili ai unui client:",
      code: `

        use GuzzleHttp\Client;

        $token = $this->authenticate();

        $baseUrl = "https://clinica.dentalbooking.ro/api/v1";
        $this->getAvailableMedcoins($token, $client, "{$baseUrl}/medcoins/available/1", 'GET');

        public function getAvailableMedcoins($token, Client $client, $url, $method = 'GET', $data = [])
        {
          try {

              $options = [
                  'headers' => [
                      'Accept' => 'application/json',
                      'Authorization' => 'Bearer ' . $token
                  ]
              ];

              if ($method === 'POST') {
                  $options['json'] = $data;
              }

              $response = $client->request($method, $url, $options);
              $statusCode = $response->getStatusCode();
              $content = json_decode($response->getBody(), true);

              $this->info("Status code: $statusCode");
              $this->info("Response: " . json_encode($content));
          } catch (\Exception $e) {
              $this->error($e->getMessage());
          }
        }
      `,
    },
    {
      description: "Transferă Medcoins:",
      code: `

        use GuzzleHttp\Client;

        $token = $this->authenticate();

        $this->transferMedcoins($token, $client, "{$baseUrl}/transfer", 'POST', [
          'receiver_id' => 2,
          'receiver_type' => 'person',
          'amount' => 10
        ]);

        public function transferMedcoins($token, Client $client, $url, $method = 'GET', $data = [])
        {
          try {

              $options = [
                  'headers' => [
                      'Accept' => 'application/json',
                      'Authorization' => 'Bearer ' . $token
                  ]
              ];

              if ($method === 'POST') {
                  $options['json'] = $data;
              }

              $response = $client->request($method, $url, $options);
              $statusCode = $response->getStatusCode();
              $content = json_decode($response->getBody(), true);

              $this->info("Status code: $statusCode");
              $this->info("Response: " . json_encode($content));
          } catch (\Exception $e) {
              $this->error($e->getMessage());
          }
        }
      `,
    },
  ];

  copyMessages: { [key: string]: string | null } = {};

  copyText(text: string, messageField: string) {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    textarea.style.position = "fixed";
    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();

    try {
      document.execCommand("copy");
      this.showCopyMessage(messageField, "Text copied!");
    } catch (err) {
      console.error("Unable to copy", err);
      this.showCopyMessage(messageField, "Failed to copy!");
    }

    document.body.removeChild(textarea);
  }

  private showCopyMessage(messageField: string, message: string) {
    this.copyMessages[messageField] = message;
    setTimeout(() => {
      this.copyMessages[messageField] = null;
    }, 2000);
  }
}
