import { Injectable } from '@angular/core';
import { ApiRequestService } from './api-request.service';
import { AuthenticationService } from './authentication.service';
import { ClinicsListService } from './clinics-list.service';
import { OffersListService } from './offers-list.service';
import { MedicsListService } from './medics-list.service';
import { ClinicInfoService } from './clinic-info.service';
import { UiNotificationsService } from '../shared/ui-notifications/ui-notifications.service';

@Injectable({
  providedIn: 'root'
})
export class FavouritesService {

  constructor(
    private apiRequestService: ApiRequestService,
    private authenticationService: AuthenticationService,
    private clinicsListService: ClinicsListService,
    private offersListService: OffersListService,
    private medicsListService: MedicsListService,
    private clinicInfoService: ClinicInfoService,
    private uiNotificationsService: UiNotificationsService
  ){ }


  generateNotification(title: string, message: string, type: string){
    this.uiNotificationsService.pushNotification({
      strong: title,
      message: message,
      type,
      icon: 'ui-2_like'
    })
  }


  get authenticatedUser(){
    return this.authenticationService.userValue !== null;
  }


  unauthenticateUserNotification(){
    this.generateNotification('Autentificare obligatorie','Adaugarea la favorite este rezervata doar membrilor inregistrati','danger');
  }


  handleApiError(response: any){
    switch(response.headers.status){
      case 401:
        this.unauthenticateUserNotification();
        break;
      
      default:
        console.error('Error handling Api response');
    }
  }
  

  addFavouriteClinic(clinicId: number){ 
    if(!this.authenticatedUser){
      this.unauthenticateUserNotification();
      return;
    }

    this.apiRequestService.addFavouriteClinic(clinicId, this.authenticationService.userValue.id, this.authenticationService.userValue.accessToken).subscribe(
      (response) => {
        this.generateNotification('Clinica favorita','Clinica a fost marcata ca favorita','success');
        this.clinicsListService.toggleFavouriteClinic(clinicId,true);
        this.authenticationService.getProfile();
      },
      response => this.handleApiError(response)
    )
  }


  deleteFavouriteClinic(clinicId: number){  
    if(!this.authenticatedUser){
      this.unauthenticateUserNotification();
      return;
    }

    this.apiRequestService.deleteFavouriteClinic(clinicId, this.authenticationService.userValue.id, this.authenticationService.userValue.accessToken).subscribe(
      (response) => {
        this.generateNotification('Clinica standard','Clinica nu mai este favorita','warning');
        this.clinicsListService.toggleFavouriteClinic(clinicId,false);
        this.authenticationService.getProfile();
      },
      (response) => this.handleApiError(response)
    )
  }
  

  addFavouriteOffer(offerId: number){  
    if(!this.authenticatedUser){
      this.unauthenticateUserNotification();
      return;
    }

    this.apiRequestService.addFavouriteOffer(offerId, this.authenticationService.userValue.id, this.authenticationService.userValue.accessToken).subscribe(
      (response) => {
        this.generateNotification('Oferta favorita','Oferta a fost marcata ca favorita','success');
        this.toggleFavouriteOffer(offerId, true);
        this.authenticationService.getProfile();
      },
      response => this.handleApiError(response)
    )
  }


  deleteFavouriteOffer(offerId: number){
    if(!this.authenticatedUser){
      this.unauthenticateUserNotification();
      return;
    }
    
    this.apiRequestService.deleteFavouriteOffer(offerId, this.authenticationService.userValue.id, this.authenticationService.userValue.accessToken).subscribe(
      (response) => {
        this.generateNotification('Oferta standard','Oferta nu mai este favorita','warning');
        this.toggleFavouriteOffer(offerId, false);
        this.authenticationService.getProfile();
      },
      response => this.handleApiError(response)
    )
  }


  toggleFavouriteOffer(offerId: number, favourite: boolean){
    this.offersListService.toggleFavouriteOffer(offerId, favourite);
    this.clinicInfoService.toggleFavouriteOffer(offerId, favourite);
  }

  
  addFavouriteMedic(medicId: number){  
    if(!this.authenticatedUser){
      this.unauthenticateUserNotification();
      return;
    }

    this.apiRequestService.addFavouriteMedic(medicId, this.authenticationService.userValue.id, this.authenticationService.userValue.accessToken).subscribe(
      (response) => {
        this.generateNotification('Medic favorit','Medicul a fost marcat ca favorit','success');
        this.toggleFavouriteMedic(medicId,true);
        this.authenticationService.getProfile();
      },
      response => this.handleApiError(response)
    )
  }


  deleteFavouriteMedic(medicId: number){  
    if(!this.authenticatedUser){
      this.unauthenticateUserNotification();
      return;
    }
    
    this.apiRequestService.deleteFavouriteMedic(medicId, this.authenticationService.userValue.id, this.authenticationService.userValue.accessToken).subscribe(
      (response) => {
        this.generateNotification('Medic standard','Medicul nu mai este favorit','warning');
        this.toggleFavouriteMedic(medicId, false);
        this.authenticationService.getProfile();
      },
      response => this.handleApiError(response)
    )
  }


  toggleFavouriteMedic(medicId: number, favourite: boolean){
    this.medicsListService.toggleFavouriteMedic(medicId, favourite);
    this.clinicInfoService.toggleFavouriteMedic(medicId, favourite);
  }
}
