import { Component, OnInit } from '@angular/core';

import { appConfig } from '../../models/AppConfigModel';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  
  appConfig = appConfig;

  data: Date = new Date();
  userEmailFocus: boolean = false;
  userPassFocus: boolean = false;
  
  loginError: boolean = false;
  authenticationInProgress: boolean = false;

  loginEmail: string ='';
  loginPassword: string ='';
  
  
  constructor(
    private authenticationService: AuthenticationService
  ) { }


  ngOnInit() {
      var body = document.getElementsByTagName('body')[0];
      body.classList.add('login-page');
      var navbar = document.getElementsByTagName('nav')[0];
      navbar.classList.add('navbar-transparent');
  }


  ngOnDestroy(){
      var body = document.getElementsByTagName('body')[0];
      body.classList.remove('login-page');
      var navbar = document.getElementsByTagName('nav')[0];
      navbar.classList.remove('navbar-transparent');
  }


  loginUser(){
    this.initAuthentication();
    this.authenticationService.login(this.loginEmail,this.loginPassword).subscribe(
      user => {
        this.authenticationSuccess();
      },
      error =>{
        switch(error.status){
          case 401:
            this.authenticationFail();
            break;
          default:
            console.error('Authentication error!');
            console.error(error);
        }
      }
    );
  }


  initAuthentication(){
    this.authenticationInProgress = true;
    this.loginError = false;
  }


  authenticationSuccess(){
    this.authenticationInProgress = false;
    this.loginError = false;
  }


  authenticationFail(){
    this.authenticationInProgress = false;
    this.loginError = true;
  }
}
