import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { DropdownItemModel } from 'app/models/DropdownItemModel';

// models
import { appConfig } from '../../models/AppConfigModel';
import { FilterModel } from '../../models/FilterModel';

@Component({
  selector: 'app-offer-filter',
  templateUrl: './offer-filter.component.html',
  styleUrls: ['./offer-filter.component.css']
})
export class OfferFilterComponent implements OnInit {

  @Output() filterEvent = new EventEmitter();

  appConfig = appConfig;
  filter: FilterModel = new FilterModel();

  constructor() { 
    // init filters
    this.resetFilters();
  }

  ngOnInit(): void {
  }

  resetFilters(){
    this.filter.name = '';
    this.filter.cities = [];
    this.filter.serviceTypes = [];
  }

  citySelect(dropdownItems: DropdownItemModel[]){
    this.filter.cities = [];
    dropdownItems.forEach(item => {
      this.filter.cities.push({
        id: item.id,
        name: item.itemName
      })
    })
  }

  serviceTypeSelect(dropdownItems: DropdownItemModel[]){
    this.filter.serviceTypes = [];
    dropdownItems.forEach(item => {
      this.filter.serviceTypes.push({
        id: item.id,
        name: item.itemName,
        active_services: []
      })
    })
  }
  
  filterItems(){
    this.filterEvent.emit(this.filter);
  }
}
