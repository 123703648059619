import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-items-length-badge',
  templateUrl: './items-length-badge.component.html',
  styleUrls: ['./items-length-badge.component.css']
})
export class ItemsLengthBadgeComponent implements OnInit {

  @Input() listOfItems: Array<any>;
  @Input() itemsType: string;
  
  constructor() {
  }

  ngOnInit(): void {
  }

}
