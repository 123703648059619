import { Injectable } from '@angular/core';


import { ApiRequestService } from './api-request.service';
import { QualificationModel } from '../models/QualificationModel';
import { DropdownItemModel } from '../models/DropdownItemModel';

@Injectable({
  providedIn: 'root'
})
export class QualificationDropdownItemsService {

  itemsList: DropdownItemModel[] = [];
  
  constructor(private apiReqestService: ApiRequestService) { 
    this.loadItems();
  }

  loadItems(){
    this.apiReqestService.getQualificationsList().subscribe(
      apiResponse => this.setItemsList(<QualificationModel[]>apiResponse.data),
    err => {
      console.error('Error on fetching cities list');
    });
  }

  setItemsList(newItemsList: QualificationModel[]): void{
    this.itemsList.length = 0; // reset the data
    newItemsList.forEach(qualification => {
      this.itemsList.push({
        id: qualification.id,
        itemName: qualification.name
      });
    });
  }
}
