import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

import { ApiRequestService } from './api-request.service';
import { UiNotificationsService } from 'app/shared/ui-notifications/ui-notifications.service';

import { appConfig } from '../models/AppConfigModel';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {

  resetPasswordSubjectBehavior: BehaviorSubject<boolean>;

  appConfig = appConfig;


  constructor(
    private apiRequestService: ApiRequestService,
    private uiNotificationService: UiNotificationsService,
    private router: Router, 
  ) { 
    this.resetPasswordSubjectBehavior = new BehaviorSubject(false)
  }


  resetPassword(resetEmail: string): boolean{
    let requestDataError = false;

    if(!resetEmail.length){
      this.uiNotificationService.pushNotification({
        type: 'danger',
        strong: 'Email obligatoriu',
        message: 'Campul email este obligatoriu',
        icon: 'now-ui-icons ui-1_bell-53'
      });
      requestDataError = true;
      return requestDataError;
    }

    this.resetPasswordSubjectBehavior.next(true);
    this.apiRequestService.resetPasswordEmail(resetEmail).subscribe(
      res => this.handleApiResponse(res),
      err => this.handleApiResponse(err)
    );

    this.resetPasswordSubjectBehavior.next(true);
    return requestDataError;
  }
  
  
  handleApiResponse(response: any){
    this.resetPasswordSubjectBehavior.next(false);
    this.router.navigate([appConfig.appModules.account.routes.login]);
    this.uiNotificationService.pushNotification({ 
      type: 'info',
      strong: 'Verificati adresa de e-mail',
      message: 'In cazul in care emailul a fost gasit, veti primi instructiuni pe adresa de email specificata',
      icon: 'now-ui-icons travel_info',
      autodismiss: true
    });
  }
}
