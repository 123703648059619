import { Injectable } from '@angular/core';

import { ApiRequestService } from './api-request.service';
import { CityModel } from '../models/CityModel';
import { DropdownItemModel } from '../models/DropdownItemModel';

@Injectable({
  providedIn: 'root'
})
export class CityDropdownItemsService {

  itemsList: DropdownItemModel[] = [];
  
  constructor(private apiReqestService: ApiRequestService) { 
    this.loadItems();
  }

  loadItems(){
    this.apiReqestService.getCitiesList().subscribe(
      apiResponse => this.setItemsList(<CityModel[]>apiResponse.data),
    err => {
      console.error('Error on fetching cities list');
    });
  }

  setItemsList(newItemsList: CityModel[]): void{
    this.itemsList.splice(0,this.itemsList.length); // reset the data
    newItemsList.forEach(city => {
      this.itemsList.push({
        id: city.id,
        itemName: city.name
      });
    });
  }
}
