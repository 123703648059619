import { Component, OnInit, Input } from '@angular/core';
import { ClinicModel } from 'app/models/ClinicModel';

@Component({
  selector: 'app-clinic-contact-widget',
  templateUrl: './clinic-contact-widget.component.html',
  styleUrls: ['./clinic-contact-widget.component.css']
})
export class ClinicContactWidgetComponent implements OnInit {

  @Input() clinic: ClinicModel;

  constructor() { }

  ngOnInit(): void {
  }

}
