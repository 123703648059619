import { Component, OnInit } from '@angular/core';
import { UIAlert, UiNotificationsService } from './ui-notifications.service';


@Component({
  selector: 'app-ui-notifications',
  templateUrl: './ui-notifications.component.html',
  styleUrls: ['./ui-notifications.component.css']
})
export class UiNotificationsComponent implements OnInit {

  alerts: UIAlert[];

  constructor(private uiNotificationsService: UiNotificationsService) {
  }

  ngOnInit(): void {
    this.alerts = this.uiNotificationsService.notifications;
  }

  public closeAlert(alert: UIAlert) {
    this.uiNotificationsService.dismissNotification(alert);
  }
}