import { MedicModel } from './MedicModel'
import { ClinicModel } from './ClinicModel'
import { OfferModel } from './OfferModel'
import { ServiceTypeModel } from './ServiceTypeModel'

export class QuickSearchModel{
    medics: MedicModel[];
    clinics: ClinicModel[];
    offers: OfferModel[];
    serviceTypes: ServiceTypeModel[];
}