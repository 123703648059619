import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

import { ApiRequestService } from './api-request.service';
import { UiNotificationsService } from 'app/shared/ui-notifications/ui-notifications.service';

import { appConfig } from '../models/AppConfigModel';


export interface ResetPasswordRequestErrorMessages{
  newPassword: string,
  validatePassword: string,
  passwordMismatch: string
}

export interface ResetPasswordRequestResult{
  errors: boolean,
  errorMessages: ResetPasswordRequestErrorMessages
}


@Injectable({
  providedIn: 'root'
})
export class NewPasswordService {

  resetPasswordRequestResult: ResetPasswordRequestResult;

  newPasswordSubjectBehaviour:  BehaviorSubject<boolean>;

  appConfig = appConfig;

  constructor(
    private apiRequestService: ApiRequestService,
    private uiNotificationService: UiNotificationsService,
    private router: Router, 
  ) {
    this.newPasswordSubjectBehaviour = new BehaviorSubject(false);
    this.resetPasswordRequestResult = {
      errors: false,
      errorMessages: {
        newPassword: '',
        validatePassword: '',
        passwordMismatch: ''
      }
    }
  }


  initNewPasswordReqest(){
    this.resetPasswordRequestResult.errors = false;
    this.resetPasswordRequestResult.errorMessages.newPassword = '';
    this.resetPasswordRequestResult.errorMessages.validatePassword = '';
    this.resetPasswordRequestResult.errorMessages.passwordMismatch = '';
    this.newPasswordSubjectBehaviour.next(true);
  }


  localDataValidation(newPassword: string, passwordValidation: string){
    if(!newPassword.length){
      this.resetPasswordRequestResult.errors = true;
      this.resetPasswordRequestResult.errorMessages.newPassword = 'Campul parola noua este obligatoriu';
    }

    if(!passwordValidation.length){
      this.resetPasswordRequestResult.errors = true;
      this.resetPasswordRequestResult.errorMessages.validatePassword = 'Campul repeta parola este obligatoriu';
    }

    if(this.resetPasswordRequestResult.errors){
      return;
    }

    if(newPassword !== passwordValidation){
      this.resetPasswordRequestResult.errors = true;
      this.resetPasswordRequestResult.errorMessages.passwordMismatch = 'Campurile parola noua si repeta parola sunt diferite';
    }
  }

    
  newPasswordRequest(token: string, email: string, newPassword: string, passwordValidation: string){
    this.initNewPasswordReqest();

    this.localDataValidation(newPassword, passwordValidation);
    if(this.resetPasswordRequestResult.errors){
      this.pushUIErrorNotification();
      this.newPasswordSubjectBehaviour.next(false);
      return;
    }

    this.apiRequestService.resetPassword(token, email, newPassword, passwordValidation).subscribe(
      res => this.handleApiResponse(res),
      err => this.handleApiError(err)
    );
  }


  handleApiResponse(response: any){
    this.pushUISuccessNotification();
    this.router.navigate([appConfig.appModules.account.routes.login]);
    this.newPasswordSubjectBehaviour.next(false);
  }


  handleApiError(response: any){
    switch (response.status) {
      case 404:
        if(response.error !== null && response.error.errors !== null && response.error.errors.password !== null){
          this.resetPasswordRequestResult.errorMessages.newPassword = response.error.errors.password[0];
          this.pushUIErrorNotification();
          this.newPasswordSubjectBehaviour.next(false);
        }
        else{
          console.error('Unhandled 404 status: ');
          console.error(response);
        }
        break;

      case 403: 
        if(response.errors !== null && response.error.errors){
          this.resetPasswordRequestResult.errorMessages.newPassword = response.error.errors;
          this.pushUIErrorNotification('Link expirat');
          this.newPasswordSubjectBehaviour.next(false);
        }
        else{
          console.error('Unhandled 403 status: ');
          console.error(response);
        }
        break;
    
      default:
        console.error('Unhandled status: ' + response.status);
        break;
    }
  }

  pushUISuccessNotification(title: string = 'Parola a fost modificata'){
    this.uiNotificationService.pushNotification({ 
      type: 'success',
      strong: title,
      message: 'Va rugam sa va autentificati',
      icon: 'now-ui-icons ui-1_bell-53',
      autodismiss: true
    });
  }


  pushUIErrorNotification(title: string = 'Formularul contine erori'){
    this.uiNotificationService.pushNotification({ 
      type: 'danger',
      strong: title,
      message: Object.values(this.resetPasswordRequestResult.errorMessages).filter(errMsg => errMsg.length),
      icon: 'now-ui-icons ui-1_bell-53',
      autodismiss: true
    });
  }
}
