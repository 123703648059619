import { Injectable } from '@angular/core';

import { ApiRequestService } from './api-request.service';
import { ServiceTypeModel } from '../models/ServiceTypeModel';
import { DropdownItemModel } from '../models/DropdownItemModel';
import { FilterModel } from 'app/models/FilterModel';

@Injectable({
  providedIn: 'root'
})
export class ServiceTypeDropdownItemsService {

  itemsList: DropdownItemModel[] = [];

  constructor(private apiReqestService: ApiRequestService) { 
    this.loadItems();
  }

  loadItems(){
    this.apiReqestService.getServiceTypesList(new FilterModel()).subscribe(
      apiResponse => this.setItemsList(<ServiceTypeModel[]>apiResponse.data),
    err => {
      console.error('Error on fetching service types list');
    });
  }

  setItemsList(newItemsList: ServiceTypeModel[]): void{
    this.itemsList.length = 0; // reset the data
    newItemsList.forEach(serviceType => {
      this.itemsList.push({
        id: serviceType.id,
        itemName: serviceType.name
      });
    });
  }
}
