import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AgmCoreModule } from '@agm/core';
import { AppRoutingModule } from './app.routing';

import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { HttpRequestInterceptor } from './_helpers/http-request-interceptor';

import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ApiDocumentationComponent } from './components/api-documentation/api-documentation.component';
import { FeatureWidgetComponent } from './ui-components/feature-widget/feature-widget.component';
import { MedicsComponent } from './components/medics/medics.component';
import { ClinicsComponent } from './components/clinics/clinics.component';
import { OffersComponent } from './components/offers/offers.component';
import { ServicesComponent } from './components/services/services.component';
import { ClinicInfoComponent } from './components/clinic-info/clinic-info.component';
import { ClinicContactWidgetComponent } from './ui-components/clinic-contact-widget/clinic-contact-widget.component';
import { ClinicWidgetComponent } from './ui-components/clinic-widget/clinic-widget.component';
import { MedicWidgetComponent } from './ui-components/medic-widget/medic-widget.component';
import { OfferWidgetComponent } from './ui-components/offer-widget/offer-widget.component';
import { OfferContentComponent } from './ui-components/offer-content/offer-content.component';
import { SimpleOfferWidgetComponent } from './ui-components/simple-offer-widget/simple-offer-widget.component';
import { SimpleMedicWidgetComponent } from './ui-components/simple-medic-widget/simple-medic-widget.component';
import { MedicContentComponent } from './ui-components/medic-content/medic-content.component';
import { ServiceWidgetComponent } from './ui-components/service-widget/service-widget.component';
import { ServiceTypesComponent } from './components/service-types/service-types.component';
import { ServicesListComponent } from './ui-components/services-list/services-list.component';
import { ClinicFilterComponent } from './ui-components/clinic-filter/clinic-filter.component';
import { CityDropdownComponent } from './ui-components/city-dropdown/city-dropdown.component';
import { ServiceTypeDropdownComponent } from './ui-components/service-type-dropdown/service-type-dropdown.component';
import { MedicFilterComponent } from './ui-components/medic-filter/medic-filter.component';
import { OfferFilterComponent } from './ui-components/offer-filter/offer-filter.component';
import { ServiceTypeFilterComponent } from './ui-components/service-type-filter/service-type-filter.component';
import { SpecialisationDropdownComponent } from './ui-components/specialisation-dropdown/specialisation-dropdown.component';
import { QualificationDropdownComponent } from './ui-components/qualification-dropdown/qualification-dropdown.component';
import { ProfileComponent } from './components/profile/profile.component';
import { RegisterComponent } from './components/register/register.component';
import { LoginComponent } from './components/login/login.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { FavouriteComponent } from './ui-components/favourite/favourite.component';
import { UiNotificationsComponent } from './shared/ui-notifications/ui-notifications.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { FooterComponent } from './shared/footer/footer.component';
import { ModalComponent } from './shared/modal/modal.component';
import { from } from 'rxjs';
import { CookiesConsentModel } from './services/cookies-consent.service';
import { QuickSearchComponent } from './components/quick-search/quick-search.component';
import { QuickSearchFilterComponent } from './ui-components/quick-search-filter/quick-search-filter.component';
import { NewsComponent } from './components/news/news.component';
import { NewsInfoComponent } from './components/news-info/news-info.component';
import { ItemsLengthBadgeComponent } from './ui-components/items-length-badge/items-length-badge.component';

import { CountItemsPipe } from './pipes/count-items.pipe';
import { NewPasswordComponent } from './components/new-password/new-password.component';
import { ExcludeClinicsWithoutImagesPipe } from './pipes/exclude-clinics-without-images.pipe';
import { MedcoinsComponent } from './medcoins/medcoins.component';
import { MedcoinsTransactionsComponent } from './medcoins-transactions/medcoins-transactions.component';

@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent,
        DashboardComponent,
        ApiDocumentationComponent,
        FeatureWidgetComponent,
        MedicsComponent,
        ClinicsComponent,
        OffersComponent,
        ServicesComponent,
        ClinicInfoComponent,
        ClinicContactWidgetComponent,
        ClinicWidgetComponent,
        MedicWidgetComponent,
        OfferWidgetComponent,
        OfferContentComponent,
        SimpleOfferWidgetComponent,
        SimpleMedicWidgetComponent,
        MedicContentComponent,
        ServiceWidgetComponent,
        ServiceTypesComponent,
        ServicesListComponent,
        ClinicFilterComponent,
        CityDropdownComponent,
        ServiceTypeDropdownComponent,
        MedicFilterComponent,
        OfferFilterComponent,
        ServiceTypeFilterComponent,
        SpecialisationDropdownComponent,
        QualificationDropdownComponent,
        ProfileComponent,
        RegisterComponent,
        LoginComponent,
        ResetPasswordComponent,
        FavouriteComponent,
        UiNotificationsComponent,
        TermsAndConditionsComponent,
        FooterComponent,
        ModalComponent,
        QuickSearchComponent,
        QuickSearchFilterComponent,
        NewsComponent,
        NewsInfoComponent,
        ItemsLengthBadgeComponent,
        CountItemsPipe,
        NewPasswordComponent,
        ExcludeClinicsWithoutImagesPipe,
        MedcoinsComponent,
        MedcoinsTransactionsComponent
    ],
    imports: [
        BrowserAnimationsModule,
        HttpClientModule,
        NgbModule,
        FormsModule,
        RouterModule,
        AppRoutingModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyB3FEafqYYEfrofW9wcZoks9vQ7LT3dfqw'
        }),
        AngularMultiSelectModule
    ],
    providers: [{
        provide: HTTP_INTERCEPTORS,
        useClass: HttpRequestInterceptor,
        multi: true},
        CookiesConsentModel
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
