import { Component, OnInit, Input } from '@angular/core';

export class FeatureWidgetModel{
  index: number; 
  route: string;
  icon: string;
  style: string;
  items: object[];
  title: string; 
  text: string;
  description: string;
  callToAction: string;
}

@Component({
  selector: 'app-feature-widget',
  templateUrl: './feature-widget.component.html',
  styleUrls: ['./feature-widget.component.css']
})
export class FeatureWidgetComponent implements OnInit {

  @Input() displayInfo: FeatureWidgetModel;

  constructor() { }

  ngOnInit(): void {
  }

}
