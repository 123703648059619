import { Injectable } from '@angular/core';
import { ApiRequestService } from './api-request.service';

@Injectable({
  providedIn: 'root'
})

export class CookiesPopupService {

  cookies: object = {
    description: '',
  };

  constructor(public apiRequestService: ApiRequestService) {
    this.getTosCookiesWeb();
  }

  getTosCookiesWeb() {
    this.apiRequestService.getTosCookiesWeb().subscribe (
      success => Object.assign(this.cookies, success),
      err => console.error(err)
    );
  }
}