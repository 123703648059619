import { Component, OnInit } from '@angular/core';
import { ApiRequestService } from 'app/services/api-request.service';
import { AuthenticationService } from 'app/services/authentication.service';

@Component({
  selector: 'app-medcoins',
  templateUrl: './medcoins.component.html',
  styleUrls: ['./medcoins.component.css']
})

export class MedcoinsComponent implements OnInit {
  webUser: any;
  searchQuery: string = '';
  searchType: string = 'clinic';
  searchResults: any[] = [];
  transferAmount: number = 0;
  selectedUser: any = null;
  successMessage: string = '';
  errorMessage: string = '';
  searchPerformed: boolean = false;

  currentPage: number = 1;
  itemsPerPage: number = 10;
  totalPages: number = 1;
  paginatedResults: any[] = [];

  constructor(
    private apiRequestService: ApiRequestService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.getProfile();
  }

  getProfile() {
    const token = this.authenticationService.userValue.accessToken;
    this.apiRequestService.getProfile(token).subscribe(
      (response) => {
        if (response.success) {
          this.webUser = response.web_user;
        }
      },
      (error) => {
        console.error("Error fetching profile information", error);
      }
    );
  }

  search() {
    const token = this.authenticationService.userValue.accessToken;
    const currentUserId = this.webUser.id;
    this.successMessage = '';
    this.errorMessage = '';


    if (!this.searchQuery.trim()) {
      return;
    }

    this.apiRequestService.searchUsers(this.searchQuery, this.searchType, currentUserId).subscribe(
      (response) => {
        if (response.success) {
          this.searchResults = Array.isArray(response.data) ? response.data : [response.data];
          this.searchPerformed = true;
          this.setPagination();
        } else {
          this.searchResults = [];
          this.searchPerformed = true;
        }
      },
      (error) => {
        console.error("Error fetching search results", error);
        this.searchPerformed = true;
      }
    );
  }

  setPagination() {
    this.totalPages = Math.ceil(this.searchResults.length / this.itemsPerPage);
    this.currentPage = 1;
    this.paginateResults();
  }

  paginateResults() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.paginatedResults = this.searchResults.slice(startIndex, endIndex);
  }

  changePage(page: number) {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.paginateResults();
    }
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.paginateResults();
    }
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.paginateResults();
    }
  }

  selectUser(user: any) {
    this.selectedUser = user;
    this.searchResults = [];
    this.paginatedResults = [];
  }

  transferMedcoins() {
    const token = this.authenticationService.userValue.accessToken;

    if (!this.selectedUser || this.transferAmount <= 0) {
      this.errorMessage = 'Please enter a valid transfer amount.';
      return;
    }

    if (this.transferAmount > this.webUser.medcoins_count) {
      this.errorMessage = 'Insufficient Medcoins. You cannot transfer more than ' + this.webUser.medcoins_count + ' Medcoins.';
      return;
    }

    const senderType = 'person';
    const receiverType = this.searchType === 'clinic' ? 'clinic' : 'person';

    const transferData = {
      sender_id: this.webUser.id,
      sender_type: senderType,
      receiver_id: this.selectedUser.id,
      receiver_type: receiverType,
      amount: this.transferAmount
    };

    this.apiRequestService.transferMedcoins(transferData).subscribe(
      (response) => {
        if (response.success) {
          this.successMessage = 'Medcoins transferred successfully to ' + this.selectedUser.name + '!';
          this.getProfile();
          this.resetForm();
        } else {
          this.errorMessage = 'Error in transferring Medcoins. Please try again.';
        }
      },
      (error) => {
        console.error("Error transferring Medcoins", error);
        this.errorMessage = 'Error in transferring Medcoins. Please try again.';
      }
    );
  }

  resetForm() {
    this.selectedUser = null;
    this.transferAmount = 0;

    this.clearSearch();
  }

  clearSearch() {
    this.searchQuery = '';
    this.searchResults = [];
    this.paginatedResults = [];
    this.searchPerformed = false;
  }
}
