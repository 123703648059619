import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { DropdownItemModel } from 'app/models/DropdownItemModel';

// models
import { appConfig } from '../../models/AppConfigModel';
import { FilterModel } from '../../models/FilterModel';

@Component({
  selector: 'app-service-type-filter',
  templateUrl: './service-type-filter.component.html',
  styleUrls: ['./service-type-filter.component.css']
})
export class ServiceTypeFilterComponent implements OnInit {

  @Output() filterEvent = new EventEmitter();

  appConfig = appConfig;
  filter: FilterModel = new FilterModel();

  constructor() { 
    // init filters
    this.resetFilters();
  }

  ngOnInit(): void {
  }

  resetFilters(){
    this.filter.name = '';
  }
  
  filterItems(){
    this.filterEvent.emit(this.filter);
  }

}
