import { KeyValue } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {NgbModal, NgbModalConfig }from '@ng-bootstrap/ng-bootstrap';
import { CookiesConsentService, CookiesConsentModel } from 'app/services/cookies-consent.service';
import { CookiesPopupService } from 'app/services/cookies-popup.service';


@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css'],
})
export class ModalComponent implements OnInit{

  @ViewChild('content', {static:true}) content: TemplateRef<any>;

  cookiesConsent: CookiesConsentModel;

  uiCheckboxes = {
    'necessary': {
      label: 'Necesare',
      disabled: true,
      checked: true
    },
    'preferences': {
      label: 'Preferinţe',
      disabled: false,
      checked: true
    },
    'statistics': {
      label: 'Statistică',
      disabled: false,
      checked: true
    },
    'marketing': {
      label: 'Marketing',
      disabled: false,
      checked: false
    },
  };
  
  closeResult: string;
  cookiesMessage: object;
  checkedValues = [];

  constructor(config: NgbModalConfig, 
              private modalService: NgbModal, 
              private cookiesPopupService: CookiesPopupService, 
              private cookiesConsentService: CookiesConsentService) {
    config.backdrop = 'static';
    config.keyboard = false;
  }
  

  ngOnInit(): void { 
    this.cookiesConsent = this.cookiesConsentService.getCookiesConsent();

    // Slice -> Skip needsConsent 
    Object.entries(this.cookiesConsent).slice(1).forEach(consentType => {
      this.uiCheckboxes[consentType[0]].checked = this.cookiesConsent[consentType[0]]
    });

    if(this.cookiesConsent.needsConsent) {
      this.modalService.open(this.content);
      this.cookiesMessage = this.cookiesPopupService.cookies;
    }
  }

  open(content) {
    this.modalService.open(content);
  }

  preventUncheck(e) {
    e.preventDefault();
    this.uiCheckboxes[0].disabled = true;
  }

  updateUiCheckboxes(event) {
    let input = event.target;    
    this.uiCheckboxes[input.value].checked = input.checked;
  }

  consentCookies() {
    for(let item in this.uiCheckboxes) {          
      this.cookiesConsent[item] = this.uiCheckboxes[item].checked;
    }
    this.cookiesConsentService.setCookiesConsent(this.cookiesConsent);
  }

  originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return 0;
  }
}

