import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor{
    constructor(
        private autenticationService: AuthenticationService
    ) { }

    
    intercept(req: HttpRequest<any>, next: HttpHandler){
        let headers: HttpHeaders = new HttpHeaders({
            // 'Content-Type':  'text/html'
            'Content-Type':  'application/json'
        })

        // user authenticated - adding token
        if(this.autenticationService.userValue !== null){
            headers = headers.append('Authorization', `Bearer ${this.autenticationService.userValue.accessToken}`);
        }

        let request = req.clone({headers});
        return next.handle(request);
    }
}
