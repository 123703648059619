import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-favourite',
  templateUrl: './favourite.component.html',
  styleUrls: ['./favourite.component.css']
})
export class FavouriteComponent implements OnInit {
  @Input() isFavourite: boolean;
  @Input() isEnabled: boolean = false;
  @Output() toggleFavouriteEvent = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  toggleFavourite(){
    this.toggleFavouriteEvent.emit(!this.isFavourite);
  }
}
