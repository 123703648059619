import { Pipe, PipeTransform } from '@angular/core';

import { ClinicModel } from '../models/ClinicModel';

@Pipe({
  name: 'excludeClinicsWithoutImages'
})
export class ExcludeClinicsWithoutImagesPipe implements PipeTransform {

  transform(items: ClinicModel[]): ClinicModel[] {
    if (!items) {
        return items;
    }
    return items.filter(item => item.cover_link !== null || item.logo_link !== null);
  }

}
