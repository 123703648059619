import { ClinicModel } from "./ClinicModel";
import { ServiceModel } from "./ServiceModel";

export class OfferModel{
    id: number;
    name: string;
    short_description: string;
    description: string;
    price: number;
    offer_services_total_price: number;
    clinic_id: number;
    services: ServiceModel;
    clinic: ClinicModel;
    in_logged_user_favorites: boolean;
    promoted: number;
}