import { Component, OnInit } from '@angular/core';
import { NewsModel } from 'app/models/NewsModel';
import { FilterModel } from '../../models/FilterModel';
import { NewsListService } from 'app/services/news-list.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  
  newsList: NewsModel[];

  constructor(private newsListService: NewsListService) { }

  ngOnInit(): void {
    this.newsList = this.newsListService.itemsList;
  }
}
