import { Injectable } from '@angular/core';

import { ApiRequestService } from './api-request.service';
import { ServiceTypeModel } from '../models/ServiceTypeModel';
import { FilterModel } from 'app/models/FilterModel';

@Injectable({
  providedIn: 'root'
})
export class ServiceTypesListService {

  itemsList: ServiceTypeModel[] = [];

  constructor(private apiReqestService: ApiRequestService) { 
    this.loadItemsList(new FilterModel());
  }

  loadItemsList(filter: FilterModel): void{
    this.apiReqestService.getServiceTypesList(filter).subscribe(
      apiResponse => this.setItemsList(<ServiceTypeModel[]>apiResponse.data),
    err => {
      console.error('Error on fetching clinics list');
    });
  }

  setItemsList(newItemsList: ServiceTypeModel[]): void{
    this.itemsList.length = 0; // reset the data
    this.itemsList.push(...newItemsList);
  }
}
