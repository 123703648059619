import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './_helpers/auth.guard';

import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ClinicsComponent } from './components/clinics/clinics.component';
import { OffersComponent } from './components/offers/offers.component';
import { ServiceTypesComponent } from './components/service-types/service-types.component';
import { MedicsComponent } from './components/medics/medics.component';
import { ClinicInfoComponent } from './components/clinic-info/clinic-info.component';
import { ProfileComponent } from './components/profile/profile.component';
import { RegisterComponent } from './components/register/register.component';
import { LoginComponent } from './components/login/login.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { NewPasswordComponent } from './components/new-password/new-password.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { QuickSearchComponent } from './components/quick-search/quick-search.component';
import { NewsComponent } from './components/news/news.component';
import { NewsInfoComponent } from './components/news-info/news-info.component';

const routes: Routes =[
    { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    { path: 'dashboard',                            component: DashboardComponent },
    { path: 'cauta/clinici',                        component: ClinicsComponent },
    { path: 'cauta/oferte',                         component: OffersComponent },
    { path: 'cauta/tipuri-servicii',                component: ServiceTypesComponent },
    { path: 'cauta/medici',                         component: MedicsComponent },
    { path: 'clinica/:clinicId',                    component: ClinicInfoComponent },
    { path: 'account/profile',                      component: ProfileComponent, canActivate: [AuthGuard] },
    { path: 'account/register',                     component: RegisterComponent},
    { path: 'account/login',                        component: LoginComponent },
    { path: 'account/reset-pass/submit',            component: ResetPasswordComponent },
    { path: 'account/reset-pass/new',               component: NewPasswordComponent },
    { path: 'termeni-si-conditii/:section',         component: TermsAndConditionsComponent },
    { path: 'cautare-rapida',                       component: QuickSearchComponent },
    { path: 'cautare-rapida/:searchName',           component: QuickSearchComponent },
    { path: 'noutati',                              component: NewsComponent },
    { path: 'noutati/:newsId',                      component: NewsInfoComponent },
];


@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes,{
          useHash: true
        })
    ],
    exports: [
    ],
})
export class AppRoutingModule { }
