import { Component, OnInit } from '@angular/core';

// models
import { appConfig } from '../../models/AppConfigModel';
import { MedicModel } from '../../models/MedicModel';
import { FilterModel } from '../../models/FilterModel';

// services
import { MedicsListService } from '../../services/medics-list.service';

@Component({
  selector: 'app-medics',
  templateUrl: './medics.component.html',
  styleUrls: ['./medics.component.css']
})
export class MedicsComponent implements OnInit {

  appConfig = appConfig;

  serviceMedicsList: MedicModel[];
  canLoadMore: number = 0;
  medicsList: MedicModel[];
  requestInProgress: boolean = false;

  constructor(
    private medicsListService: MedicsListService,
  ) { }

  ngOnInit(): void {
    this.serviceMedicsList = this.medicsListService.itemsList;
    this.medicsList = [];
    
    this.medicsListService.requestInProgress.subscribe(res => {
      this.requestInProgress = res;

      // empty the component medics list and reload
      this.medicsList.splice(0, this.medicsList.length);
      this.canLoadMore = 0;
      this.loadMoreMedicsFromServiceList();
    });

    // this.filterList(new FilterModel(), false);
  }


  loadMoreMedicsFromServiceList(amountToLoad: number = 6){
    let sliceStart = this.medicsList.length;
    let sliceStop = Math.min(this.medicsList.length + amountToLoad, this.serviceMedicsList.length);
    let addMedics: MedicModel[] = this.serviceMedicsList.slice(sliceStart, sliceStop);
    this.medicsList.push(...addMedics);

    this.canLoadMore =  this.serviceMedicsList.length - this.medicsList.length;
  }


  displayMoreItems(){
    this.loadMoreMedicsFromServiceList(3);
  }


  filterList(filter: FilterModel, scroll: boolean = true){
    this.medicsListService.loadItemsList(filter);

    if(scroll){
      let el = document.querySelector('.section-items-search-result');
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
