import { Component, OnInit } from '@angular/core';

import { appConfig } from '../../models/AppConfigModel';
import { OffersListService } from '../../services/offers-list.service';
import { OfferModel } from '../../models/OfferModel';
import { FilterModel } from '../../models/FilterModel';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.css']
})
export class OffersComponent implements OnInit {

  appConfig = appConfig;

  offersList: OfferModel[];

  constructor(private offersListService: OffersListService) { }

  ngOnInit(): void {
    this.offersList = this.offersListService.itemsList;
    this.filterList(new FilterModel(), false);
  }

  filterList(filter: FilterModel, scroll: boolean = true){
    this.offersListService.loadItemsList(filter);

    if(scroll){
      let el = document.querySelector('.section-items-search-result');
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
