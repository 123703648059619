import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { ApiRequestService } from './api-request.service';
import { AuthenticationService } from './authentication.service';
import { UiNotificationsService } from '../shared/ui-notifications/ui-notifications.service';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  registerProcessInProgress: BehaviorSubject<boolean>;
  registerProcessErrorFields: string[] = [];
  registerProcessErrorMessages: string[] = []; 

  constructor(
    private apiRequestService: ApiRequestService,
    private authenticationService: AuthenticationService,
    private uiNotificationService: UiNotificationsService,
  ) {
    this.registerProcessInProgress = new BehaviorSubject(false);
  }


  registerUser(email: string, password: string, passwordCheck: string){
    this.registerProcessErrorMessages = [];
    this.registerProcessErrorFields = [];
    
    this.registerProcessInProgress.next(true);
    this.apiRequestService.register(email, password, passwordCheck).subscribe(
      res => this.handleApiSuccess(res, email, password),
      err => this. handleApiError(err)
    );
  }


  handleApiSuccess(apiResponse: any, email: string, password: string){
    this.uiNotificationService.pushNotification({
      type: 'success',
      strong: '',
      message: 'Cont utilizator creat',
      icon: '',
      autodismiss: true
    });

    this.registerProcessInProgress.next(false);
    this.authenticationService.login(email,password);
  }


  handleApiError(apiError: any){
    this.processApiMessage(apiError.error.error[0]);
    this.registerProcessInProgress.next(false);

    this.uiNotificationService.pushNotification({
      type: 'danger',
      strong: 'Formularul contine erori', 
      message: this.registerProcessErrorMessages,
      icon: 'business_bulb-63',
      autodismiss: true
    });
  }


  processApiMessage(error: any){
    for(let field in error){
      this.registerProcessErrorFields.push(field);
      this.registerProcessErrorMessages.push(...error[field]);
    }
  }
}
