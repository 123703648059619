import { Component, OnInit, EventEmitter, Output } from '@angular/core';

// models
import { appConfig } from '../../models/AppConfigModel';
import { DropdownItemModel } from '../../models/DropdownItemModel';

// services
import { CityDropdownItemsService } from '../../services/city-dropdown-items.service';

@Component({
  selector: 'app-city-dropdown',
  templateUrl: './city-dropdown.component.html',
  styleUrls: ['./city-dropdown.component.css']
})
export class CityDropdownComponent implements OnInit {

  @Output() selectCitiesEvent = new EventEmitter<DropdownItemModel[]>();

  appConfig = appConfig;

  dropdownList: DropdownItemModel[] = [];
  selectedItems: DropdownItemModel[] = [];
  dropdownSettings = {};

  constructor(private cityDropdownItemsService: CityDropdownItemsService) { }

  ngOnInit() {
    this.dropdownList = this.cityDropdownItemsService.itemsList;
    this.selectedItems = [];
    this.dropdownSettings = {
      singleSelection: true,
      text: "Selecteaza oras",
      selectAllText: "Selecteaza toate",
      unSelectAllText: "Deselecteaza toate",
      enableSearchFilter: false,
      classes: "",
      lazyLoading: true
    };
  }

  onItemSelect(item:any){
    this.notifySelectionChange();
  }
  
  OnItemDeSelect(item:any){
    this.notifySelectionChange();
  }
  
  onSelectAll(items: any){
    this.notifySelectionChange();
  }
  
  onDeSelectAll(items: any){
    this.notifySelectionChange();
  }
  
  notifySelectionChange(){
    this.selectCitiesEvent.emit(<DropdownItemModel[]>this.selectedItems);
  }
}
