import { EnvironmentConfig } from '../config/EnvironmentConfig';

export const appConfig = {
  appName: 'DENTALBOOKING',
  appSlogan: 'Toate serviciie dentare intr-o singura aplicatie!',
  appModules: {
    'clinics': {
      icon: 'shopping_shop',
      cssColor: 'info',
      routes: {
        'search': 'cauta/clinici',
        'info': 'clinica/',
        'register': `${EnvironmentConfig.appUrl}/admin/register`,
      }
    },
    'offers': {
      icon: 'shopping_tag-content',
      cssColor: 'danger',
      routes: {
        'search': 'cauta/oferte'
      }
    },
    'services': {
      icon: 'education_paper',
      cssColor: 'warning',
      routes: {
        'search': 'cauta/servicii'
      }
    },
    'serviceTypes':{
      icon: 'education_paper',
      cssColor: 'warning',
      routes: {
        'search': 'cauta/tipuri-servicii'
      }
    },
    'medics': {
      icon: 'business_badge',
      cssColor: 'success',
      routes: {
        'search': 'cauta/medici'
      }
    },
    'news': {
      icon: 'objects_globe',
      cssColor: 'success',
      routes: {
        'news': 'noutati'
      }
    },
    'account': {
      icon: 'users_single-02',
      cssColor: 'primary',
      routes: {
        'profile': 'account/profile',
        'login': 'account/login',
        'register': 'account/register',
        'reset': 'account/reset-pass/submit',
        'new-password': 'account/reset-pass/new'
      }
    },
    'termsAndConds': {
      cssColor: 'primary',
      routes: {
        'termsAndConds': 'termeni-si-conditii/general'
      }
    },
    'web': {
      cssColor: 'primary',
      routes: {
        'web': 'termeni-si-conditii/web'
      }
    },
    'privacy': {
      cssColor: 'primary',
      routes: {
        'privacy': 'termeni-si-conditii/confidentialitate'
      }
    },
    'customersData': {
      cssColor: 'primary',
      routes: {
        'customersData': 'termeni-si-conditii/date-clienti'
      }
    },

  },

  appLinks: {
    registerClinic: `${EnvironmentConfig.appUrl}/`,
  },

  socialMedia: {
    facebook: "https://www.facebook.com/Dentalbooking-101944365443568/",
    twitter: "https://twitter.com/Dentalbooking2",
    instagram: "https://www.instagram.com/dentalbookingapp/"
  }

}