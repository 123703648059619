import { Component, OnInit, Input } from '@angular/core';

import { FavouritesService } from 'app/services/favourites.service';
import { AuthenticationService } from 'app/services/authentication.service';

import { appConfig } from '../../models/AppConfigModel';
import { ClinicModel } from '../../models/ClinicModel';
import { WebUserModel } from '../../models/WebUserModel';

@Component({
  selector: 'app-clinic-widget',
  templateUrl: './clinic-widget.component.html',
  styleUrls: ['./clinic-widget.component.css'],
})
export class ClinicWidgetComponent implements OnInit {

  @Input() clinic: ClinicModel;

  appConfig = appConfig;
  webUser: WebUserModel = null;

  constructor(
    private favouritesService: FavouritesService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.authenticationService.userObservable.subscribe(
      webUser => { this.webUser = webUser }
    )
  }
  
  toggleFavouriteItem(isFavourite: boolean){
    if(isFavourite){
      this.favouritesService.addFavouriteClinic(this.clinic.id);
    }
    else{
      this.favouritesService.deleteFavouriteClinic(this.clinic.id);
    }
  }

}
