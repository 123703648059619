import { Injectable } from '@angular/core';
import { appConfig } from '../models/AppConfigModel';

export class CookiesConsentModel {
  needsConsent: boolean;
  necessary: boolean;
  preferences: boolean;
  statistics: boolean;
  marketing: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CookiesConsentService {
  appConfig = appConfig;

  // number of days the consent is available
  private cookiesConsentDuration = 30;

  // the name of the cookie
  private cookiesConsentName = this.appConfig.appName.toLowerCase() + '-cookies-consent';
  

  constructor(private cookiesConsent: CookiesConsentModel) { 
    this.retrieveCookiesConsent();
  }

  private getDefaultCookiesConsent(): CookiesConsentModel{
    return {
      needsConsent: true,
      necessary: true,
      preferences: true,
      statistics: true,
      marketing: false
    } 
  }

  private retrieveCookiesConsent() {
    // no cookies saved
    if( !document.cookie.includes(this.cookiesConsentName)) {
     this.cookiesConsent = this.getDefaultCookiesConsent();
     return;
    }

    // retrieve from cookies
    try{
      let cookies = document.cookie.split('; ');
      let json = '';
      cookies.forEach(cookie => {
        if(cookie.indexOf(this.cookiesConsentName) === 0){
          json = cookie.replace(this.cookiesConsentName + '=','');
        }
      });
      this.cookiesConsent = JSON.parse(json);
    }
    catch(e){
      // failsafe option
      this.cookiesConsent = this.getDefaultCookiesConsent();
    }
  }

  public setCookiesConsent(cookiesConsent: CookiesConsentModel) {
    this.cookiesConsent = cookiesConsent;
    this.cookiesConsent.needsConsent = false;
    this.saveCookie();   
  }

  private saveCookie(){
    //hack prevention
    if(!this.cookiesConsent.necessary) {
      this.cookiesConsent.necessary = true;
    }
    let d: Date = new Date();
    d.setTime(d.getTime() + this.cookiesConsentDuration * 24 * 60 * 60 * 1000);
    let expires: string = "expires=" + d.toUTCString();
    document.cookie = this.cookiesConsentName + "=" + JSON.stringify(this.cookiesConsent) + "; " + expires;
  }

  public getCookie(name: string) {
    return document.cookie.includes(name='true');
  }

  public deleteCookie(name) {
    document.cookie = `${name} = `
  }

  public getCookiesConsent():CookiesConsentModel {
    return this.cookiesConsent;
  }
}
