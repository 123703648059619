import { stringify } from '@angular/compiler/src/util';
import { Injectable } from '@angular/core';


export interface UIAlert {
  type: string;
  strong?: string;
  message: string | string[];
  icon?: string;
  autodismiss?: boolean;
}


@Injectable({
  providedIn: 'root'
})
export class UiNotificationsService {

  private maxActiveNotifications: number = 3;
  private autoDismissTimer: number = 4000;
  notifications: UIAlert[] = [];
  notificationsPool: UIAlert[] = [];

  constructor() {
  }

  pushNotification(notification: UIAlert){
    if(typeof notification.message === 'string'){
      notification.message = [notification.message]
    }

    if(this.notifications.length < this.maxActiveNotifications){
      this.notifications.push(notification);
      if(notification.autodismiss !== false){
        setTimeout(() => this.dismissNotification(notification), this.autoDismissTimer);
      }
    }
    else{
      this.notificationsPool.push(notification);
    }
  }

  dismissNotification(notification: UIAlert){
    // delete active notifications
    const index: number = this.notifications.indexOf(notification);
    this.notifications.splice(index, 1);

    // take out other notifications from the pool
    if(this.notificationsPool.length){
      this.notifications.push(this.notificationsPool.shift());
    }
  }
}
