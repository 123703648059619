import { Injectable } from '@angular/core';

import { ApiRequestService } from './api-request.service';
import { ClinicModel } from '../models/ClinicModel';
import { FilterModel } from 'app/models/FilterModel';

@Injectable({
  providedIn: 'root'
})
export class ClinicsListService {

  itemsList: ClinicModel[] = [];

  constructor(private apiReqestService: ApiRequestService) {
    this.loadItemsList(new FilterModel());
  }

  loadItemsList(filter: FilterModel): void{
    this.apiReqestService.getClinicsList(filter).subscribe(
      apiResponse => this.setItemsList(<ClinicModel[]>apiResponse.data),
    err => {
      console.error('Error on fetching clinics list');
      console.error(err);
    });
  }

  setItemsList(clinicsList: ClinicModel[]): void{
    this.itemsList.length = 0; // reset the data
    this.itemsList.push(...clinicsList);
  }

  toggleFavouriteClinic(clinicId: number, favourite: boolean){
    this.itemsList.filter(clinic => clinicId == clinic.id)
      .forEach(clinic => clinic.in_logged_user_favorites = favourite);
  }
}
