import { Component, OnInit } from '@angular/core';

import { ResetPasswordService } from '../../services/reset-password.service';
import { appConfig } from '../../models/AppConfigModel';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  appConfig = appConfig;
  data: Date = new Date();
  focus: boolean = false;

  requestStatusSubscription: Subscription;
  requestInProgress: boolean = false;

  formError: boolean = false;
  resetEmail: string = '';

  
  constructor(
    private resetPasswordService: ResetPasswordService
  ) { }


  ngOnInit() {
      var body = document.getElementsByTagName('body')[0];
      body.classList.add('login-page');
      var navbar = document.getElementsByTagName('nav')[0];
      navbar.classList.add('navbar-transparent');

      this.requestStatusSubscription = this.resetPasswordService.resetPasswordSubjectBehavior.subscribe(
        requestInProgress => this.requestInProgress = requestInProgress
      );
  }


  ngOnDestroy(){
      var body = document.getElementsByTagName('body')[0];
      body.classList.remove('login-page');
      var navbar = document.getElementsByTagName('nav')[0];
      navbar.classList.remove('navbar-transparent');

      this.requestStatusSubscription.unsubscribe();
  }


  resetPassword(){
    this.formError = false;
    this.formError = this.resetPasswordService.resetPassword(this.resetEmail);
  }
}
