import { ServiceTypeModel } from "./ServiceTypeModel";
import { ClinicModel } from "./ClinicModel";

export class ServiceModel{
    id: number;
    name: string;
    description: string;
    price: number;
    clinic_id: number;
    service_type_id: number;
    service_type: ServiceTypeModel;
    clinic: ClinicModel;
}