import { Injectable } from '@angular/core';

import { ApiRequestService } from './api-request.service';
import { ClinicModel } from '../models/ClinicModel';

@Injectable({
  providedIn: 'root'
})
export class ClinicInfoService {

  clinicId: number;
  clinicInfo: ClinicModel = new ClinicModel();

  constructor(private apiReqestService: ApiRequestService) { }


  getClinicInfo(clinicId: number){
    this.apiReqestService.getClinicInfo(clinicId).subscribe(apiResponse => {
      this.clinicId = clinicId;
      Object.assign(this.clinicInfo, <ClinicModel>apiResponse.data);
      return this.clinicInfo;
    },
    err => {
      console.error('Error fetching clinic info');
    });
  }


  toggleFavouriteMedic(medicId: number, favourite: boolean){
    // daca nu e medicul ultimei clinici incarcate, nu trebuie modificat nimic
    if(this.clinicInfo.medics == undefined){
      return;
    }

    this.clinicInfo.medics.filter(medic => medicId == medic.id)
      .forEach(medic => medic.in_logged_user_favorites = favourite);
  }
  

  toggleFavouriteOffer(offerId: number, favourite: boolean){
    // daca nu e oferta ultimei clinici incarcate, nu trebuie modificat nimic
    if(this.clinicInfo.active_offers == undefined){
      return;
    }

    this.clinicInfo.active_offers.filter(offer => offerId == offer.id)
      .forEach(offer => offer.in_logged_user_favorites = favourite);
  }
}
