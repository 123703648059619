import { QualificationModel } from "./QualificationModel";
import { SpecialisationModel } from "./SpecialisationModel";
import { ClinicModel } from "./ClinicModel";

export class MedicModel{
    id: number;
    clinic_id: number;
    name: string;
    surname: string;
    profile_photo_link: string;
    specialisations: SpecialisationModel[];
    qualifications: QualificationModel[];
    clinic: ClinicModel;
    status: string;
    grad: string;
    phone: string;
    email: string;
    in_logged_user_favorites: boolean;
}