import { Component, OnInit, Input } from '@angular/core';

import { appConfig } from '../../models/AppConfigModel';
import { ServiceModel } from '../../models/ServiceModel';

@Component({
  selector: 'app-service-widget',
  templateUrl: './service-widget.component.html',
  styleUrls: ['./service-widget.component.css']
})
export class ServiceWidgetComponent implements OnInit {

  appConfig = appConfig;

  @Input() service: ServiceModel;

  constructor() { }

  ngOnInit(): void {
  }

}
