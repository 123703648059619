import { Injectable } from '@angular/core';
import { NewsModel } from 'app/models/NewsModel';
import { ApiRequestService } from './api-request.service';

@Injectable({
  providedIn: 'root'
})
export class NewsInfoService {

  newsId: number;
  newsInfo: NewsModel = new NewsModel();

  constructor(private apiReqestService: ApiRequestService) { }

  getNewsInfo(newsId: number){
    this.apiReqestService.getNewsInfo(newsId).subscribe(apiResponse => {
      this.newsId = newsId;
      Object.assign(this.newsInfo, <NewsModel>apiResponse.data);
      return this.newsInfo;
    },
    err => {
      console.error('Error fetching clinic info');
    });
  }
}
