import { Injectable } from '@angular/core';

import { ApiRequestService } from './api-request.service';
import { OfferModel } from '../models/OfferModel';
import { FilterModel } from '../models/FilterModel';

@Injectable({
  providedIn: 'root'
})
export class OffersListService {

  itemsList: OfferModel[] = [];

  constructor(private apiReqestService: ApiRequestService) { 
    this.loadItemsList(new FilterModel());
  }

  loadItemsList(filter: FilterModel): void{
    this.apiReqestService.getOffersList(filter).subscribe(
      apiResponse => this.setItemsList(<OfferModel[]>apiResponse.data),
    err => {
      console.error('Error on fetching clinics list');
    });
  }

  setItemsList(newItemsList: OfferModel[]): void{
    this.itemsList.length = 0; // reset the data
    this.itemsList.push(...newItemsList);
  }

  toggleFavouriteOffer(offerId: number, favourite: boolean){
    this.itemsList.filter(offer => offerId == offer.id)
      .forEach(offer => offer.in_logged_user_favorites = favourite);
  }
}
