import { CityModel } from "./CityModel";
import { ServiceTypeModel } from "./ServiceTypeModel";
import { SpecialisationModel } from "./SpecialisationModel";
import { QualificationModel } from "./QualificationModel";

export class FilterModel{
    name: string;
    cities: CityModel[];
    serviceTypes: ServiceTypeModel[];
    specialisations: SpecialisationModel[];
    qualifications: QualificationModel[];
}