import { Component, OnInit, Input } from '@angular/core';

import { appConfig } from '../../models/AppConfigModel';
import { ServiceModel } from '../../models/ServiceModel';

@Component({
  selector: 'app-services-list',
  templateUrl: './services-list.component.html',
  styleUrls: ['./services-list.component.css']
})
export class ServicesListComponent implements OnInit {

  appConfig = appConfig;

  @Input() services: ServiceModel[];

  constructor() { }

  ngOnInit(): void {
  }

}
