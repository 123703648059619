import { Component, OnInit } from '@angular/core';

import { RegisterService } from '../../services/register.service';

import { appConfig } from '../../models/AppConfigModel';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  appConfig = appConfig;
  data : Date = new Date();
  focus;
  focus1;
  focus2;

  userName: string = '';
  email: string = '';
  password: string = '';
  passwordCheck: string = '';
  requestInProgress: boolean = false;
  errorFields: any;
  
  constructor(private registerService: RegisterService) { 
    this.setFormErrors([]);
    this.registerService.registerProcessInProgress.subscribe(
      status => {
        this.requestInProgress = status;    
        this.setFormErrors(this.registerService.registerProcessErrorFields);
      }
    );
  }

  ngOnInit() {
    var body = document.getElementsByTagName('body')[0];
    body.classList.add('login-page');
    var navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.add('navbar-transparent');
  }


  ngOnDestroy(){
    var body = document.getElementsByTagName('body')[0];
    body.classList.remove('login-page');
    var navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.remove('navbar-transparent');
  }


  setFormErrors(errors: string[]){
    this.errorFields = {
      email: false,
      password: false,
      passwordCheck: false
    };

    errors.forEach(error => {
      switch(error){
        default: 
        this.errorFields[error] = true;
      }
    });
  }


  registerUser(){
    this.setFormErrors([]);
    this.registerService.registerUser(this.email,this.password,this.passwordCheck);
  }

}
