import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ApiRequestService } from './api-request.service';
import { MedicModel } from '../models/MedicModel';
import { FilterModel } from '../models/FilterModel';

@Injectable({
  providedIn: 'root'
})
export class MedicsListService {

  itemsList: MedicModel[] = [];

  requestInProgress: BehaviorSubject<boolean>;

  constructor(private apiReqestService: ApiRequestService) { 
    this.requestInProgress = new BehaviorSubject(false);
    this.loadItemsList(new FilterModel());
  }

  loadItemsList(filter: FilterModel): void{
    this.requestInProgress.next(true);
    
    this.setItemsList([]);
    this.apiReqestService.getMedicsList(filter).subscribe(
      apiResponse => this.setItemsList(<MedicModel[]>apiResponse.data),
    err => {
      console.error('Error on fetching medics list');
    });
  }

  setItemsList(newItemsList: MedicModel[]): void{
    this.itemsList.length = 0; // reset the data
    this.itemsList.push(...newItemsList);
    this.requestInProgress.next(false);
  }

  toggleFavouriteMedic(medicId: number, favourite: boolean){
    let medic = this.itemsList.filter(medic => medicId == medic.id);
    medic[0].in_logged_user_favorites = favourite;
  }
}
