import { Injectable } from '@angular/core';
import { FilterModel } from 'app/models/FilterModel';
import { NewsModel } from 'app/models/NewsModel';
import { ApiRequestService } from './api-request.service';

@Injectable({
  providedIn: 'root'
})
export class NewsListService {
  
  itemsList: NewsModel[] = [];

  constructor(private apiReqestService: ApiRequestService) {
    this.loadItemsList(new FilterModel());
  }

  loadItemsList(filter: FilterModel){
    this.apiReqestService.getNewsList().subscribe(
      apiResponse => this.setItemsList(<NewsModel[]>apiResponse.data),
    err => {
      console.error('Error on fetching clinics list');
    });
  }

  setItemsList(newItemsList: NewsModel[]): void{
    this.itemsList.length = 0; // reset the data
    this.itemsList.push(...newItemsList);
  }
}
