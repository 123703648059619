import { Component, OnInit } from '@angular/core';

import { appConfig } from '../../models/AppConfigModel';
import { ServiceTypeModel } from '../../models/ServiceTypeModel';
import { FilterModel } from '../../models/FilterModel';
import { ServiceTypesListService } from '../../services/service-types-list.service';

@Component({
  selector: 'app-service-types',
  templateUrl: './service-types.component.html',
  styleUrls: ['./service-types.component.css']
})
export class ServiceTypesComponent implements OnInit {

  appConfig = appConfig;

  serviceTypesList: ServiceTypeModel[];

  constructor(private serviceTypesListService: ServiceTypesListService) { }

  ngOnInit(): void {
    this.serviceTypesList = this.serviceTypesListService.itemsList;
    this.filterList(new FilterModel(), false);
  }

  filterList(filter: FilterModel, scroll: boolean = true){
    this.serviceTypesListService.loadItemsList(filter);

    if(scroll){
      let el = document.querySelector('.section-items-search-result');
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }

}
