import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DropdownItemModel } from 'app/models/DropdownItemModel';

// Models
import { FilterModel } from 'app/models/FilterModel';



@Component({
  selector: 'app-quick-search-filter',
  templateUrl: './quick-search-filter.component.html',
  styleUrls: ['./quick-search-filter.component.css']
})
export class QuickSearchFilterComponent implements OnInit {
  @Input() defaultSearchTerm: string;
  @Output() filterEvent = new EventEmitter();

  filter: FilterModel = new FilterModel;

  constructor() {
    this.resetFilters();
  }

  ngOnInit(): void {
    this.filter.name = this.defaultSearchTerm;
  }

  resetFilters(){
    this.filter.name = '';
    this.filter.cities = [];
    this.filter.serviceTypes = [];
  }

  citySelect(dropdownItems: DropdownItemModel[]){
    this.filter.cities = [];
    dropdownItems.forEach(item => {
      this.filter.cities.push({
        id: item.id,
        name: item.itemName
      })
    })
  }

  filterItems(){
    this.filterEvent.emit(this.filter);
  }
}