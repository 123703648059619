import { Injectable } from '@angular/core';

import { ApiRequestService } from './api-request.service';
import { ServiceModel } from '../models/ServiceModel';

@Injectable({
  providedIn: 'root'
})
export class ServicesListService {

  itemsList: ServiceModel[] = [];

  constructor(private apiReqestService: ApiRequestService) { 
    this.loadItemsList();
  }

  loadItemsList(): void{
    this.apiReqestService.getServicesList().subscribe(
      apiResponse => this.setItemsList(<ServiceModel[]>apiResponse.data),
    err => {
      console.error('Error on fetching clinics list');
    });
  }

  setItemsList(newItemsList: ServiceModel[]): void{
    this.itemsList.length = 0; // reset the data
    this.itemsList.push(...newItemsList);
  }
}
