import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { DropdownItemModel } from 'app/models/DropdownItemModel';

// models
import { appConfig } from '../../models/AppConfigModel';
import { FilterModel } from '../../models/FilterModel';

@Component({
  selector: 'app-medic-filter',
  templateUrl: './medic-filter.component.html',
  styleUrls: ['./medic-filter.component.css']
})
export class MedicFilterComponent implements OnInit {

  @Output() filterEvent = new EventEmitter();

  appConfig = appConfig;
  filter: FilterModel = new FilterModel();

  constructor() { 
    // init filters
    this.resetFilters();
  }

  ngOnInit(): void {
  }
  
  resetFilters(){
    this.filter.name = '';
    this.filter.cities = [];
    this.filter.specialisations = [];
    this.filter.qualifications = [];
  }
  
  citySelect(dropdownItems: DropdownItemModel[]){
    this.filter.cities = [];
    dropdownItems.forEach(item => {
      this.filter.cities.push({
        id: item.id,
        name: item.itemName
      })
    })
  }
 
  specializationSelect(dropdownItems: DropdownItemModel[]){
    this.filter.specialisations = [];
    dropdownItems.forEach(item => {
      this.filter.specialisations.push({
        id: item.id,
        name: item.itemName
      })
    })
  } 

  qualificationSelect(dropdownItems: DropdownItemModel[]){
    this.filter.qualifications = [];
    dropdownItems.forEach(item => {
      this.filter.qualifications.push({
        id: item.id,
        name: item.itemName
      })
    })
  }

  filterItems(){
    this.filterEvent.emit(this.filter);
  }
}
