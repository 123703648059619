import { Component, OnInit } from '@angular/core';

// models
import { appConfig } from '../../models/AppConfigModel';
import { FilterModel } from '../../models/FilterModel';
import { ClinicModel } from '../../models/ClinicModel';

// services
import { ClinicsListService } from '../../services/clinics-list.service';

@Component({
  selector: 'app-clinics',
  templateUrl: './clinics.component.html',
  styleUrls: ['./clinics.component.css']
})
export class ClinicsComponent implements OnInit {

  appConfig = appConfig;

  clinicsList: ClinicModel[];

  constructor( private clinicsListService: ClinicsListService ) { }

  ngOnInit(): void {
    this.clinicsList = this.clinicsListService.itemsList;
    this.filterList(new FilterModel(), false);
  }

  filterList(filter: FilterModel, scroll: boolean = true){
    this.clinicsListService.loadItemsList(filter);

    if(scroll){
      let el = document.querySelector('.section-items-search-result');
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
