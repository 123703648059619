import { Component, OnInit, EventEmitter, Output } from '@angular/core';

// models
import { appConfig } from '../../models/AppConfigModel';
import { DropdownItemModel } from '../../models/DropdownItemModel';

// services
import { ServiceTypeDropdownItemsService } from '../../services/service-type-dropdown-items.service';

@Component({
  selector: 'app-service-type-dropdown',
  templateUrl: './service-type-dropdown.component.html',
  styleUrls: ['./service-type-dropdown.component.css']
})
export class ServiceTypeDropdownComponent implements OnInit {
  @Output() selectServiceTypeEvent = new EventEmitter<DropdownItemModel[]>();

  appConfig = appConfig;

  dropdownList: DropdownItemModel[] = [];
  selectedItems: DropdownItemModel[] = [];
  dropdownSettings = {};

  constructor(private serviceTypeDropdownItemsService: ServiceTypeDropdownItemsService) { }

  ngOnInit() {
    this.dropdownList = this.serviceTypeDropdownItemsService.itemsList;

    this.selectedItems = [];
    
    this.dropdownSettings = {
      singleSelection: true,
      text:"Selecteaza tip serviciu",
      selectAllText:'Selecteaza toate',
      unSelectAllText:'Deselecteaza toate',
      enableSearchFilter: false,
      classes:"",
      lazyLoading: true
    };
  }

  onItemSelect(item:any){
    this.notifySelectionChange();
  }
  
  OnItemDeSelect(item:any){
    this.notifySelectionChange();
  }
  
  onSelectAll(items: any){
    this.notifySelectionChange();
  }
  
  onDeSelectAll(items: any){
    this.notifySelectionChange();
  }
  
  notifySelectionChange(){
    this.selectServiceTypeEvent.emit(<DropdownItemModel[]>this.selectedItems);
  }

}
