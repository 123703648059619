import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-after-email-verification-url",
  templateUrl: "./after-email-verification-url.component.html",
  styleUrls: ["./after-email-verification-url.component.css"],
})
export class AfterEmailVerificationUrlComponent implements OnInit {
  ngOnInit(): void {
    // code
  }
}
